import React, { Component } from 'react'
import MicSvg from '../../../../components/svg/MicSvg'
import { endRecording, startRecording } from '../../../../actions/ReorAudio';
import PlaySvg from '../../../../components/svg/PlaySvg';
import { ReactMic } from 'react-mic';
import wave from "../../../../images/recordGif.gif"
let second = 0;
let minute = 0;
let interval;
export class TestMic extends Component {
    state={
        timer:'00:00',
        micPosition:'pause',
        audioBase64:'',
        isRecording:false
    }
    getTimerFormst=(time)=>{
        if(time < 10){
            return `0${time}`;
        }else{
            return time;
        }
    }
    startTimer =  ()=>{
        this.setState({timer: `00:00`})
        interval = setInterval(()=>{
           
            if(second<60){
                second++;
            }else{
                minute++;
                second = 0;
            }
            if(second >20){
                this.stopRecordBtn()
            }
            this.setState({timer: `${this.getTimerFormst(minute)}:${this.getTimerFormst(second)}`})
          
        }, 1000);
       
    }
    stopTimer = ()=>{
        clearInterval(interval);
        second = 0;
        minute = 0;
    }
    startRecordingBtn(){
        this.startTimer();
        this.setState({micPosition:'record', audioBase64:''});
        startRecording();
        this.setState({isRecording:true})
    }

    stopRecordBtn(){
        this.stopTimer();
        this.setState({micPosition:'pause'});
        const result = endRecording();
        this.setState({audioBase64: result?.audio_url})
        this.setState({isRecording:false})
        // this.props.changeStateValue({name:'mainModal.recorder_file', value: result?.audioName})
    }
    changePage(){
        this.stopTimer();
        this.setState({
            timer:'00:00',
            micPosition:'pause',
            audioBase64:''
        });
        second = 0;
        minute = 0;
        clearInterval(interval);
        try{
            const result = endRecording();
        }catch(err){}
       
        this.setState({isRecording:false})
        setTimeout(()=>{ this.props.onChangePage();},100)
    }
    componentWillUnmount(){
        // try{
        //     const result = endRecording();
        // }catch(err){}
    }
  render() {
    const {timer, micPosition, audioBase64, isRecording} = this.state;
    // console.log(audioBase64, micPosition)
    return (
        <div className='w-full flex flex-col gap-[10px] justify-center items-center'>
            <h2>{timer}</h2>
            <h3 className='text-center text-[28px] uppercase font-[700]'>TEST YOUR MICROPHONE</h3>
            <div className='flex flex-col items-center gap-[20px]'>
               <div className='bg-orange w-[50px] mt-[20px] h-[50px] flex justify-center items-center rounded-full shadow '>
                    <MicSvg size={30} />
               </div>
               {/* {
                    <ReactMic
                        record={isRecording}
                        className={`w-[150px] h-[60px] ${isRecording?"":'hidden'}`}
                        onStop={()=> console.log("stop")}
                        onData={()=> console.log("data")}
                        strokeColor="#000000"
                        backgroundColor="white"
                    />
               } */}
                 {isRecording?  <img className='h-[30px] max-sm:w-[50%] w-[120px]' alt='recording' src={wave} />:null}
              
               {
                     audioBase64 !=""?
                    <>
                        <audio className='max-sm:w-[200px] w-[220px] h-[40px] max-[320px]:w-[150px]' controls src={audioBase64} />
                        <h4 className='flex items-center gap-[4px]'>Click <PlaySvg /> to play your test recording.</h4>
                        <p className='text-center'>If your microphone works properly please click Start Exam button</p>
                    </>:
                    <>
                         <h4>You have 20 seconds to speak…</h4>
                         <p className='text-center'>To complete this activity, you must allow access to your system's microphone. Click  the button below to Start.</p>
                    </>
                }
               <div>
               
                <div className='flex gap-[20px]'>
                    {
                        micPosition === "pause"?
                        <button onClick={this.startRecordingBtn.bind(this)}  className='bg-blue text-[12px] text-white p-[5px_10px] rounded-[5px]'>{audioBase64 ===""? "Test Microphone":"Test Mic Again"}</button>:
                        <button onClick={this.stopRecordBtn.bind(this)}  className='bg-blue text-[12px] text-white p-[5px_10px] rounded-[5px]'>Stop</button>

                    }
                
                    <button onClick={this.changePage.bind(this)} className='bg-orange text-[12px] text-white p-[5px_10px] rounded-[5px]'>{audioBase64 ===""?"Skip":"Start Exam"}</button>
                </div>
              
               </div>
            </div>
        </div>
    )
  }
}

export default TestMic
