import React, { Component } from 'react'
import CourseLessonDrop from './components/CourseLessonDrop'
import { connect } from 'react-redux'
import { withHooks } from '../../../../actions/withHooks'
import { getUnlockExams } from '../../../../actions/StaffAction'
import Card1 from '../../../../components/cards/Card1'
import { bigAlert, mixinAlert } from '../../../../actions/SweetAlerts'
import { unlockStudentHomwork } from '../../../../actions/StaffAction'
import EmptyPage from '../../../../components/EmptyPage'
export class UnlockHw extends Component {
    state={
        unlockHws:[],
        btnDisable:false
    }
    getHw=(data)=>{
        this.props.getUnlockExams(data?.courseId, data?.lessonId, this.props.params.id, 1,'')
        .then(resp=>{
            this.setState({unlockHws:resp?.data})
        })
    }
    unlockHwBtn=(data)=>{
        // if(data?.check_exists=='1'){
        //     mixinAlert( "warning","The student has already passed the PT. Passed PTs cannot be retaken.")
        //     return
        // }
        if(data.is_block === 0 ){
            this.setState({btnDisable:true})
            const params={
                is_block: 1,
                id: data?.id,
                is_homework: 1
            }
            let formData = new FormData();
            for (const property in params) {
                if (params[property])
                    formData.append(property, params[property]);
            }
            formData.append("_method", "put");
            this.props.unlockStudentHomwork(`examLockUnlock/${data?.id}`, formData)
            .then(resp=>{
                this.setState({btnDisable:false})
                if(resp === "success"){
                    this.getHw({courseId: data?.course_id, lessonId: data?.lesson_id})
                    bigAlert("Unlocked!", this.props.lang?.hw_unlck_succ, "success")
                }else{
                    // bigAlert("Error!", "Something went wrong.", "error")
                }
            })
        }else{
            mixinAlert( "warning","The PT has already been unlocked.")
        }
       
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }
    getCoursePage(){
        const courseVisible = window.location.search;
        if(courseVisible==""){
            return true
        }else{
          return false;
        }
    }
  render() {
    const {unlockHws, btnDisable} = this.state;
    const {lang} = this.props;
    // console.log(unlockHws)
    return (
        <div className='w-full'>
            <CourseLessonDrop studentType={0} onSelectData={this.getHw.bind(this)} />
            {/* {unlockHws.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, select course and lesson" subTitle="" /></div>:null} */}
            <div className='w-full grid-full-220 mt-[20px]'>
                {this.getCoursePage()?
                    unlockHws?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.exam?.title}
                                statusBisible={false}
                                btn={false}
                                btn2={true}
                                btn2Text={ data.is_block !== 0 ? lang?.lock: lang?.unlock}
                                btn2Color={ data.is_block !== 0? "bg-gray-400": 'bg-blue'}
                                cheating={ data.is_block !== 0? lang?.unlocked: lang?.locked}
                                onCLickBtn={this.unlockHwBtn.bind(this, data)}
                                cheating2={data?.check_exists=='1'?lang?.already_passed:data?.check_exists=='2'?lang?.have_failed:lang?.not_taken_yet}
                                disabled={btnDisable}
                                cheatingColor2={data?.check_exists=='1'?"txt-blue":data?.check_exists=='2'?"txt-red": "txt-dark-gray"}
                                lesson={data?.lesson?.title}
                            />
                        )
                    }):null
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getUnlockExams, unlockStudentHomwork}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnlockHw))
