import React, { Component } from 'react'

export class Part3Intro extends Component {
  render() {
    return (
        <div className='w-full flex flex-col gap-[10px] justify-center items-center'>
           <h3 className='txt-blue text-[28px] font-[600]'>Part 3</h3>
            <h3 className='text-center text-[28px] uppercase font-[700]'>Instruction</h3>
            <div className='flex flex-col gap-[14px] mt-[20px] items-center'>
                <p  className='text-center'>In this part, you will be asked some more general questions based on the topic from part 2.</p>
                <button onClick={()=>this.props.onChangePage()} className='bg-blue p-[4px_10px] text-[14px] text-white rounded-[5px]'>Start now</button>
            </div>
        </div>
    )
  }
}

export default Part3Intro
