import React, { Component } from 'react'
import Header from './Header'
import Audio from '../exams/Audio'
import { resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import { extractTextFromHTML } from '../../actions/getRole'

export class StudentPoolingAnswer extends Component {
    closeModal=()=>{
        this.props.resetStateValue({name:"studentPoolingAnswersModal"})
    }
  render() {
    const {studentPoolingAnswersModal} = this.props;
    console.log(studentPoolingAnswersModal)
    return (
      <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[900px]  shadow bg-white rounded-[10px] max-[940px]:ml-[20px] max-[940px]:mr-[20px]'>
                <Header closeBtn={this.closeModal.bind(this)} title={"Student Answer"} />
                <div className='p-[20px] max-h-[70vh] overflow-auto'>
                    {
                        studentPoolingAnswersModal?.data?.map((data, i)=>{
                            if(data?.student_answers?.length !==0){
                                if(  data?.question_type === "speaking_type_1" || data?.question_type === "speaking_type_2" || data?.question_type === "speaking_type_3" ||  data?.question_type === "writing_task_1" || data?.question_type === "writing_task_2" ){
                                    return(
                                        <div key={i} className='border p-[7px] rounded-[8px] border-dashed mb-[15px]'>
                                            {
                                                data?.question_type === "speaking_type_1" || data?.question_type === "speaking_type_2" || data?.question_type === "speaking_type_3"?
                                                <>
                                                 <h3 className='text-[16px] font-[600] txt-blue'>Speaking Part {data?.question_type === "speaking_type_1"? 1: data?.question_type === "speaking_type_2" ?2: 3}</h3>
                                                 {
                                                    studentPoolingAnswersModal?.data?.filter(x=> x.question_type ===  data?.question_type)?.map((item, j)=>{
                                                        return(
                                                            <div className='flex items-center gap-[10px] ml-[5px]'>
                                                                {
                                                                    data?.question_type == "speaking_type_1" || data?.question_type== "speaking_type_3"?
                                                                     <span className='inline-block w-[5px] h-[5px] rounded-full bg-black'></span>:null
                                                                }
                                                                {
                                                                    data?.question_type =="speaking_type_2"?
                                                                    <p key={j} dangerouslySetInnerHTML={{__html: item?.description}}></p>:
                                                                    <p key={j}>{extractTextFromHTML(item?.description)}</p>

                                                                }
                                                                
                                                            </div>
                                                        )
                                                    })
                                                 }
                                                </>
                                                :null
                                            }
                                            {
                                                data?.question_type === "writing_task_1" || data?.question_type === "writing_task_2" ?
                                                <>
                                               
                                                 <h3 className='text-[16px] font-[600] txt-blue'>Writing Part {data?.question_type === "writing_task_1"? 1: 2}</h3>
                                                 <div className='border-b pb-[10px] mb-[10px]'>
                                                    <h4 className='text-[16px] font-[600] txt-blue'>Question:</h4>
                                                    {
                                                        data?.image_full_url?
                                                        <img className='max-w-[100%] mb-[15px]' src={data?.image_full_url} />:null
                                                    }
                                                    <div dangerouslySetInnerHTML={{__html: data?.description}}></div>
                                                </div>
                                                </>:null
                                            }
                                           
                                            {
                                                data?.question_type === "speaking_type_1" || data?.question_type === "speaking_type_2" || data?.question_type === "speaking_type_3"?
                                                <Audio url={`https://vip-storage.ams3.digitaloceanspaces.com/public/${data?.student_answers[0]?.answer}`} />:null
                                            }
                                            {
                                                data?.question_type === "writing_task_1" || data?.question_type === "writing_task_2"?
                                                <p className='text-[14px]  '><span className='font-[600] txt-blue text-[16px] mr-[10px]'>Student Answer:</span>  {data?.student_answers[0]?.answer} </p>:null
                                            }
                                        </div>
                                    )
                                }
                            }
                            
                           
                        })
                    }

                    {
                        studentPoolingAnswersModal?.data?.filter(data => data?.question_type === "speaking_type_1" || data?.question_type === "speaking_type_2" || data?.question_type === "speaking_type_3" ||  data?.question_type === "writing_task_1" || data?.question_type === "writing_task_2").length === 0 ?
                        <div>
                            <p className='text-center txt-orange'>This does not include the speaking and writing question types.</p>
                        </div>:null
                    }
                     <div>
                        <button onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Close</button>
                    </div>
                </div>
               
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentPoolingAnswersModal: state.Data.studentPoolingAnswersModal
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudentPoolingAnswer)
