import React, { Component } from 'react'
import SectionHeader from '../../components/SectionHeader'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { connect } from 'react-redux'
import { getStaffExamResults } from '../../actions/StaffAction'
import moment from 'moment'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import Card1 from '../../components/cards/Card1'
import { getExamPartScore } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer'
import Search from '../../components/Search'
import EmptyPage from '../../components/EmptyPage'
import { getSATScore, ieltsScoreCalculator } from '../../actions/getRole'
export class TeacherExamResults extends Component {
  state={
    page:1,
    search:'',
  }

  componentDidMount(){
    this.props.getStaffExamResults({page:1, keyword:'', type:'monthly_exam_result',  examType:'current'});
    window.scrollTo(0,0)
  }
 
  getResultsBySearch=(search)=>{
    this.setState({search:search})
    this.props.getStaffExamResults({page:this.state.page, keyword:search, type:'monthly_exam_result',  examType:'current'})
  }
  loadMore=(page)=>{
    this.setState({page:page})
    this.props.getStaffExamResults({page:page, keyword:this.state.search, type:'monthly_exam_result',  examType:'current'})
  }
  getPlagiarism(data){
    let cases = [];
    if(data){
        data.map((pl, i)=>{
            cases.push(+pl.case)
        })
        if(cases.length !== 0){
            if(Math.max(...cases) == 3){
                return '90%';
            }else if(Math.max(...cases) == 1){
                return '40%';
            }else if(Math.max(...cases) == 0){
                return '0%';
            }
        }else{
            return '0%';
        } 
    }else{
        return '0%';
    } 
    
}
showResultBtn=(data)=>{
  if(data?.exam?.exam_type === "pooling_standart"){
    this.props.changeStateValue({  name:'partScoreModal.position', value:true });
    this.props.changeStateValue({  name:'partScoreModal.title', value:data?.exam_title});
    this.props.changeStateValue({  name:'partScoreModal.data', value:[{
      title:data?.exam_title,
      points: ieltsScoreCalculator(data?.score),
      out_of: 9
    }]});
    return
  }
  this.props.getExamPartScore({id:data?.id, type:data?.exam?.exam_type =="pooling_dynamic"?"dynamic":'exam', exam_type:'current'})
  .then(resp=>{
    if(resp == 'success'){
      this.props.changeStateValue({  name:'partScoreModal.position', value:true });
      this.props.changeStateValue({  name:'partScoreModal.title', value:data?.exam_title});
    }
  })
}
  render() {
    const {staffExamResults, lang} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="exam_results" />
            <div className='max-[1100px]:mt-[120px] flex justify-center max-[800px]:mt-[180px]'>
              <Search largWidth={true} onSearchData={search=>this.getResultsBySearch(search)} />
            </div>
            {staffExamResults.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No results found" subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
                {
                  staffExamResults?.map((data, i)=>{
                    return(
                      <Card1 
                        key={i}
                        title={data?.student?.full_name}
                        titleLink={`/students/${data?.student?.id}`}
                        exam={data?.exam?.title}
                        status={data?.status}
                        time={data?.spend_time}
                        grade={getSATScore(data.exam_title, +data.score, data?.exam?.course_id == 134 && data?.exam?.exam_type == "pooling_standart"?"ielts":"normal")}
                        statusText={data?.status == 1?lang?.pass:lang?.fail}
                        date={moment(data?.updated_at).format("HH:mm, DD/MM/YY")}
                        cheatingExam={this.getPlagiarism(data.plagiarism)}
                        onCLickBtn={this.showResultBtn.bind(this, data)}
                      />
                    )
                  })
                }
            </div>
            {
                staffExamResults?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={staffExamResults?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  staffExamResults: state.Data.staffExamResults,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getStaffExamResults, getExamPartScore, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherExamResults)


