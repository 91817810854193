import React, { Component } from 'react'
import { mixinAlert } from '../../../actions/SweetAlerts';
import { connect } from 'react-redux';
import { insertNewData } from '../../../actions/MainAction';
export class AdminModalFooter extends Component {
    state={
        btnDisabled:false
    }
    saveBtn=(data)=>{
    
        let permission = true;
        for(const required of data?.requireds){
            if(data[required]===""){
                permission = false
            }
        }
        if(permission){
            this.setState({btnDisabled:true})
            let formData = new FormData();
            for(const property in data){
                if(data[property])
                    formData.append(property, data[property]);
            }
            
            
            if(data?.id && data?.id !== ""){
                formData.append("_method", "put");
            }
            if(data?.lesson_mode ||data?.lesson_mode?.length==0){
                let lesson_mode = [];
                for(const item of data?.lesson_mode){lesson_mode.push(item?.value)};
                formData.append("lesson_modes", lesson_mode);
            }
            if(data?.courses ||data?.courses?.length==0){
                let courses = [];
                for(const item of data?.courses){courses.push(item?.value)};
                formData.append("courses", courses);
            }
            if(data?.required_id || data?.required_id?.length==0){
                let required_id = [];
                for(const item of data?.required_id){required_id.push(item?.value)};
                formData.append("requireds", JSON.stringify(required_id));
            }
            if(data?.assign_role || data?.assign_role?.length==0){
                let assign_role = [];
                for(const item of data?.assign_role){assign_role.push(item?.value)};
                formData.append("assign_role", assign_role);
            }
            if(data?.email2 && data?.email2 !==""){
                formData.append("email", data?.email2)
            }
            this.props.insertNewData(data?.url, formData)
            .then(resp=>{
                this.setState({btnDisabled:false})
                if(resp === "success"){
                    data.afterCallFunction(...data?.afterCallParams);
                    this.props.closeBtn()
                    mixinAlert("success", `Data ${data?.id && data?.id !== ""?"Edited":"Added"} successfully`);
                }else{
                    mixinAlert("error", 'Something went wrong!');
                }
            })
        }else{
            mixinAlert("info", "Please, fill the all required fields");
        }
    }
  render() {
    const {btnDisabled} = this.state;
    const {closeBtn=()=>{}, data} = this.props;
    return (
        <div className='flex mt-[20px] items-center justify-between'>
            <button disabled={btnDisabled} onClick={()=>{closeBtn()}} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Close</button>
            <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Save</button>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminModalFooter)
