import React, { Component } from 'react'
import { withHooks } from '../../actions/withHooks';
import { connect } from 'react-redux';
import { getExamStart, insertNewDataV2, getExamId } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import Header from '../../components/header/Header';
import Parent from '../../components/exams/Parent';
import { bigAlert, mixinAlert } from '../../actions/SweetAlerts';
import Countdown, {zeroPad} from 'react-countdown';
import { changeStateValue } from '../../redux/MainReducer';
export class StartExam extends Component {
    state={
        title:"",
        examId:'',
        btnDisable:false,
        timer:'',
        minute:'',
        openIndex:111,
        fixedPosition:false,
        btnActiveDisable:false
    }
    componentDidMount(){

        this.props.changeStateValue({name:'examsData', value:[]})
        if(localStorage.getItem('userToken')){
          const data = jwtDecode(localStorage.getItem('userToken'));
          this.props.getExamStart(this.props.params.id, data.sub);
          this.props.getExamId(this.props.params.id, data.sub)
          .then(resp=>{
            // console.log(resp)
            if(resp==="error"){
                this.props.navigate("/")
            }else{
                this.setState({title: resp?.exam_title, examId: resp?.id, timer: resp?.updated_at, minute: resp?.exam?.duration_minutes})
            }
            
          })
        }
        window.scrollTo(0,0);
        const main = document.querySelector("main");
        if(main){
            main.scrollTo(0, 0);
            main.addEventListener("scroll", (e)=>{
                if(main?.scrollTop >120){
                    this.setState({fixedPosition:true})
                }else{
                    this.setState({fixedPosition:false})
                }
            })
        }
    }
    componentWillUnmount(){
        this.props.changeStateValue({name:'examsData', value:[]})
    }
    submitExamData(examId){
        // if(!this.state.btnActiveDisable){
        //     mixinAlert("warning", "You should spent at least 40 minutes before submitting.")
        //     return
        // }
        if(localStorage.getItem('userToken')){
            const jwt = jwtDecode(localStorage.getItem('userToken'));

            const submitExam = {
                "studentExam_id": examId,
                "exam_id": `${this.props.params.id}`,
                "student_id": jwt.sub
            }
            this.props.insertNewDataV2("student-exam-submit", submitExam)
            .then(resp=>{
            this.setState({btnDisable:false});

                if(resp === "success"){
                bigAlert(
                    "Done!",
                    "Exam submitted successfully",
                    "success"
                )
                this.props.navigate("/exam-results")
                }else{
                mixinAlert("error", "Something went wrong");
                }
            })
        }
        
    }

    renderer( { hours, minutes, seconds, completed}){
        // let exam = new Date(this.state.timer).getTime() + 40 * 60 * 1000;
        // let now = new Date().getTime();
        // if(now >= exam){
        //     if(!this.state.btnActiveDisable){
        //         this.setState({btnActiveDisable: true})
        //     }
        // }
        if(!completed){
            if(hours === 0 && minutes === 2 && seconds === 0 && window.location.pathname.split('/').includes('exam-start')){
                mixinAlert("info", "You have 2 minutes left. Please submit your exam. After 2 minutes, the system will lock the exam and you will get 0 score. Nothing will be saved. Please submit now")
            }
          return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
        }else{
            return <span className='txt-blue text-[20px] font-bold'>00:00:00</span>
        }
    }

    stopTimer(){
        if( window.location.pathname.split('/').includes('exam-start')){
            // this.props.navigate("/exam-results")
            this.submitExamData(this.state.examId)
        }
    }

   
  render() {
    const {examsData, lang, authCodeModal} = this.props;
    const {title, btnDisable, examId, minute, timer, openIndex, fixedPosition, btnActiveDisable} = this.state;
    // console.log(authCodeModal)

  
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Start now" />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
          <div className={`flex justify-center ${fixedPosition?"fixed top-0 bg-[var(--body)] w-[calc(100vw_-_300px)] z-50 max-[1100px]:w-full max-[1100px]:mt-[105px] max-[800px]:mt-[175px]":""}`}>
            <div className={`bg-white flex-col pl-[57px] flex items-center  pt-[20px] pb-[20px] rounded-[12px] justify-between pr-[57px]`}>
                    <h3 className='font-bold txt-light-black text-[28px]'>{title}</h3>
                    {
                        timer != ''?
                        <span className='txt-blue text-[20px] font-bold'>Time: {` `}
                            <Countdown date={new Date(timer).getTime() + (minute)*60000} renderer={this.renderer.bind(this)}
                                onComplete={this.stopTimer.bind(this)}>
                            </Countdown>
                        </span>:<span className='txt-blue text-[20px] font-bold'>00:00:00</span>
                    }
                    
            </div>
          </div>
            <div className='flex flex-col gap-[10px] mt-[20px]'>
                {
                examsData?.map((data, i)=>{
                    return(
                    <Parent openPosition={openIndex===i} index={i} onClickOpen={(index)=>this.setState({openIndex:index})} type="exam" key={i} parentIndex={i} data={data} status={data?.status} />
                    )
                })
                }
            </div>
            {
                 examsData.length !== 0 && !(examsData?.find(data=> data.status === "Unanswered") || examsData?.find(data=> data.status === "Partially Answered"))?
                 <div className='mt-[20px] flex justify-center items-center'>
                     <button disabled={btnDisable} onClick={this.submitExamData.bind(this, examId)} className={`bg-blue w-[140px] h-[30px] text-[14px] rounded-[6px] text-white font-semibold`}>{lang?.submit}</button>
                </div>:
                null
            }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examsData: state.Data.examsData,
    lang: state.Data.lang,
    authCodeModal: state.Data.authCodeModal,
  });
  const mapDispatchToProps = {getExamStart,getExamId, insertNewDataV2, changeStateValue}
  export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StartExam))
