import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import Input from '../../../components/modals/components/Input';
import TextEditor from '../../../components/TextEditor';
import FileInput from '../../../components/FileInput';
import { deleteData, insertDataWithParams } from '../../../actions/MainAction';
import { bigAlert, confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import ExamQuestionModal from '../exam_pool/ExamQuestionModal';
import { getOneExamQuestion } from '../../../actions/StaffAction';

export class FullParentModal extends Component {
    state={
        parentVisible:false,
        questionVisible:false,
        btnDisable:false
    }

    insertParent=(data)=>{
        if(data?.title !="" ){
            this.setState({btnDisable:true});
            let formData = new FormData();
            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property]);
                }
            }
            let url = "pooling-exam-questions";
            if(data?.id && data?.id != ''){
                url=`pooling-exam-questions/${data?.id}`;
                formData.append("_method", "put")
            }
            this.props.insertDataWithParams(url, formData)
            .then(resp=>{
                if(resp?.msg == "success"){
                    this.props.getAfterChange()
                    this.setState({parentVisible:false})
                    this.props.changeStateValue({
                        name:"poolingFullParentModal",
                        value: {
                            position:true,
                            ...resp?.data?.data
                        }
                    })
                    mixinAlert("success", "Exam created successfully")
                }else{
                    mixinAlert("error", 'Something went wrong')
                }
                // console.log(resp)
            })
            // console.log(data)
        }
    }

    addSingleBtn=(poolingFullParentModal, type)=>{
        let data = {
            ...this.props.poolingExamQuestionData,
            position:true,
            parent_id:poolingFullParentModal?.id,
            category_id: poolingFullParentModal?.category_id,
            sub_category_id:poolingFullParentModal?.sub_category_id,
            section_id:poolingFullParentModal?.section_id,
            question_type:type,
            level: poolingFullParentModal?.level,
            answer_options:[
                {
                    id:'',
                    title:'A',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:"B",
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'C',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'D',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'E',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'F',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'G',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'H',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'I',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
            ]
        }
        this.props.changeStateValue({
            name:'poolingExamQuestionData',
            value: data
        })
    }
    addFreeTextBtn=(type, poolingFullParentModal)=>{
        let data = {
            ...this.props.poolingExamQuestionData,
            position:true,
            parent_id:poolingFullParentModal?.id,
            category_id: poolingFullParentModal?.category_id,
            sub_category_id: poolingFullParentModal?.sub_category_id,
            section_id: poolingFullParentModal?.section_id,
            question_type:type,
            answer_options:[]
        }
        this.props.changeStateValue({
            name:'poolingExamQuestionData',
            value: data
        })
    }
    onEditBtn=(data)=>{
        let obj = {
            ...data,
            answer_options:data?.answers,
            position:true
        }
        this.props.changeStateValue({
            name:"poolingExamQuestionData",
            value:obj
        })
    }

    onDeleteBtn=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(alertResp=>{
            if(alertResp === "success"){
                this.props.deleteData(`pooling-exam-questions/${data?.id}`)
                .then(resp=>{
                    if(resp==="success"){
                        bigAlert("Success!", "Question Deleted successfully!", "success");
                        this.props.getOneExamQuestion(data?.parent_id)
                        .then(resp2=>{
                            this.props.changeStateValue({
                                name:"poolingFullParentModal",
                                value: {
                                    position:true,
                                    ...resp2
                                }
                            })
                        })
                    }else{
                        bigAlert("Error!", "Something went wrong!", "error")
                    }
                })
            }
        })
    }

    closeModal=()=>{
        this.props.resetStateValue({
            name:"poolingFullParentModal"
        })
    }
  render() {
    const {poolingFullParentModal, poolingExamQuestionData} = this.props;
    const {parentVisible, btnDisable, questionVisible} = this.state;
    console.log(poolingFullParentModal)
    return (
      <div className='background_shadow flex justify-center items-center'>
            <div className='w-[900px] bg-white max-h-[70vh] overflow-auto shadow rounded-[10px] p-[20px]'>
                <div className='border p-[5px] rounded-[10px]'>
                    <div>
                        <h2 onClick={()=>this.setState({parentVisible: !parentVisible})} className='text-[18px] font-semibold cursor-pointer'>Parent {poolingFullParentModal?.id && poolingFullParentModal?.id !==""? `: ${poolingFullParentModal?.title}`:''}</h2>
                    </div>
                    {
                        parentVisible?
                        <div>
                            <Input name="poolingFullParentModal.title" value={poolingFullParentModal?.title} required title='Title' />
                        
                            <div className='mt-[20px]'>    
                                <TextEditor name="poolingFullParentModal.description" value={poolingFullParentModal?.description} title={"Description"} />
                            </div>
                            <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
                                <FileInput type="image" name="poolingFullParentModal.image" placeholder={'Choose a image'} value={poolingFullParentModal?.image_full_url} valueName="poolingFullParentModal.image_full_url" title="Choose a image"/>
                                <FileInput type="video" name="poolingFullParentModal.video" placeholder={'Choose a video'} value={poolingFullParentModal?.video_full_url} valueName="poolingFullParentModal.video_full_url" title="Choose a video"/>
                                <FileInput type="audio" name="poolingFullParentModal.audio" placeholder={'Choose a audio'} value={poolingFullParentModal?.audio_full_url} valueName="poolingFullParentModal.audio_full_url" title="Choose a audio"/>
                            </div>
                            <div className='flex justify-center mt-[20px]'>
                                <button disabled={btnDisable} onClick={this.insertParent.bind(this, poolingFullParentModal)} className='w-[90px] h-[30px] bg-blue rounded-[5px] text-[14px] font-semibold text-white'>
                                    Save parent
                                </button>
                            </div>
                        </div>:null
                    }
                </div>
                {
                    poolingFullParentModal?.id !=""?
                    <div className='border p-[5px] rounded-[10px] mt-[20px]'>
                        <div className=''>
                            <h2 onClick={()=>this.setState({questionVisible: !questionVisible})} className='text-[18px] font-semibold cursor-pointer'>Questions</h2>
                        </div>
                        <div>
                            {
                                questionVisible?
                                <div>
                                  <div className='flex items-center gap-[20px]'>
                                        <button onClick={this.addSingleBtn.bind(this, poolingFullParentModal, "single_choice")} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                                            Single Choice
                                        </button>
                                        <button onClick={this.addSingleBtn.bind(this, poolingFullParentModal, "multiple_choice")} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                                            Multiple Choice
                                        </button>
                                        <button onClick={this.addFreeTextBtn.bind(this, "free_text", poolingFullParentModal)} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                                            Free text
                                        </button>
                                        <button onClick={this.addFreeTextBtn.bind(this, "drop_down", poolingFullParentModal)} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                                            Drop down
                                        </button> 
                                        <button onClick={this.addFreeTextBtn.bind(this, "speaking_type_1", poolingFullParentModal)} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                                           Speaking P. 1
                                        </button> 
                                        <button onClick={this.addFreeTextBtn.bind(this, "speaking_type_2", poolingFullParentModal)} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                                           Speaking P. 2
                                        </button> 
                                        <button onClick={this.addFreeTextBtn.bind(this, "speaking_type_3", poolingFullParentModal)} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                                           Speaking P. 3
                                        </button> 
                                  </div>

                                  <div className='bg-gray-100 p-[5px] rounded-[10px] mt-[20px] flex flex-col gap-[10px]'>
                                  {
                                    poolingFullParentModal?.questions?.map((item, j)=>{
                                        return(
                                            <div className='bg-white shadow p-[10px] rounded-[10px] flex justify-between items-center' key={j}>
                                                <h3>{item?.title}</h3>
                                                {/* <h3>{item?.question_type}</h3> */}
                                                <div className='flex items-center gap-[10px]'>
                                                    <button onClick={this.onEditBtn.bind(this, item)} className='bg-orange text-[14px] p-[5px_8px] text-white font-semibold rounded-[5px]'>Edit</button>
                                                    <button onClick={this.onDeleteBtn.bind(this, item)} className='bg-red text-[14px] p-[5px_8px] text-white font-semibold rounded-[5px]'>Delete</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                  }
                                  </div>
                                </div>:null
                            }
                        </div>
                    </div>
                    :null
                }
                <div className='mt-[20px]'>
                    <button onClick={this.closeModal.bind(this)} className='bg-red text-[14px] text-white p-[5px_8px] rounded-[5px]'>Close</button>
                </div>
            </div>

            {
              poolingExamQuestionData?.position?
              <ExamQuestionModal getAfterChange={()=>this.props.getAfterChange()} />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingFullParentModal: state.Data.poolingFullParentModal,
    poolingExamQuestionData: state.Data.poolingExamQuestionData,
});
const mapDispatchToProps = {changeStateValue, insertDataWithParams, deleteData, getOneExamQuestion, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(FullParentModal)
