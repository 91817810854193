import React, { Component } from 'react'
import img from "../images/fairImg.jpeg"
import Header from './modals/Header'
import { changeStateValue } from '../redux/MainReducer'
import { connect } from 'react-redux'
export class FairModal extends Component {
    closeBtn=()=>{
        this.props.changeStateValue({name:"fairModalPosition", value: false})
    }

    closeBackground(e){
        if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
          this.closeBtn();
        }
      }
  render() {
    return (
      <div onClick={this.closeBackground.bind(this)} className='background_shadow flex justify-center items-center'> 
            <div className='bg-white w-[600px] shadow rounded-[10px] max-[640px]:ml-[20px] max-[640px]:mr-[20px]'>
                <Header closeBtn={this.closeBtn.bind(this)} title="Dünya Universitetləri Bakıda" />
               <div className='p-[10px]'>
                    <img src={img} className='rounded-[10px]' />
                    <div className='mt-[20px] flex justify-center'>
                        <a target='_blank' href="https://www.bit.ly/celtfairs" className='bg-blue text-white p-[7px_10px] rounded-[6px] text-[14px]'>Qeydiyyatdan keç</a>
                    </div>
               </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(FairModal)
