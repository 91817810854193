import { CELTGO_API, MAIN_API, MAIN_APIV2, PUBLIC_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
import axios from "axios";
import { bigAlert, mixinAlert } from "./SweetAlerts";
import Swal from "sweetalert2";
import { loginToPublicStaff } from "./PublicActions";
import { check2FAAuth, check2FAAuth2, get2FAUser } from "./TwoFAActions";
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
        "Accept": "application/json",
        // "Content-Type": "application/json"
    }
}

export const getErrors=(error)=>async dispatch => {
    if(error.response?.status !== 422){
       dispatch(changeStateValue({name:'errorPageData', value:{position: true, code: error.response?.status}})) 
    }
    
}

export const verifyEmail = (email)=>async dispatch => {
    return await axios.post(`${MAIN_API}/emailVerify`, email)
    .then(resp=>{
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const loginAction = (params) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}))
    const info = navigator?.appVersion
    // console.log(info)
    return await axios.post(`${MAIN_API}/login`, {...params, info, login_status:'access'}, {
        headers:{"Accept": "application/json"}
    }).then(resp=>{
        localStorage.removeItem("fairAlert")
        // console.log(resp.data)
        // return
        localStorage.setItem("FAToken", btoa(resp.data?.access_token));
        return dispatch(get2FAUser())
        .then(respUser=>{
            // console.log(respUser)
            if(respUser?.role == "company_head" || respUser?.role == "office_manager"){
                // localStorage.setItem("FAToken", resp.data?.access_token);
                return dispatch(check2FAAuth2())
                .then(resp22=>{
                    if(resp22?.success){
                        return "FA";
                    }else{
                        localStorage.setItem("userToken", resp.data?.access_token);
                        return "FA2"
                    }
                })
                
            }else{
                localStorage.setItem("userToken", resp.data?.access_token);
                return dispatch(checkUser())
                .then(resp=>{
                    return resp;
                })
            }
        })
       
    }).catch(async err=>{
       return await axios.post(`${PUBLIC_API}/login-public-user`, {...params, email: params?.username})
        .then(resp=>{
           
            localStorage.setItem("vipPublicToken", resp?.data?.token);
            dispatch(changeStateValue({name:'publicUser', value: resp?.data?.user}))
            dispatch(changeStateValue({name:"loader", value:false}))
            return "success";
        }).catch(err=>{
            dispatch(changeStateValue({name: "loader", value:false}));
            return "error"
        })
    })
}
const celtgoLogin=()=>{
    axios.post(`${CELTGO_API}/login`, {username:'muhasib@celt.az', password:"12345678"})
    .then(resp=>{
        localStorage.setItem("celtgo_token", resp.data.token)
    })
}
export const logOutAction = () => async (dispatch) => {
    return await  
    axios({
      method: 'post',
      url: `${MAIN_API}/logout`,
      headers: apiHeaders()
    }).then(response =>{
      localStorage.removeItem('userToken');
      localStorage.removeItem('unreadMsg')
      localStorage.setItem('role', '')
     // window.location.reload()
     dispatch(changeStateValue({name:'user', value:''}))
       return "success"
    }).catch(err =>{return "error"})
  }
// const storeLoginLogs=async (id)=>{
//     const info = navigator?.appVersion
//     axios.post(`${MAIN_API}/login-logs`, {user_id:id, info},{
//         headers: apiHeaders()
//     }).then(resp=>{
//         console.log(resp.data)
//     }).catch(err=>{
//         console.log(err.response)
//     })
// }
export const checkUser = ()=> async dispatch=>{
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/user-info`, {
        headers: apiHeaders()
    }).then(resp=>{
        // console.log(resp)
        if(resp?.data?.user_active == 0){
            dispatch(changeStateValue({name: "loader", value:false}));
            return "error";
            
        }
        if(resp.data?.role == "student"){
            if(resp.data?.manual_lock_status == 0 && resp.data?.attendance_lock_status == 0){
                dispatch(changeStateValue({
                    name: 'user',
                    value: resp.data
                }));
                dispatch(changeStateValue({name: "loader", value:false}));
                return "success"
            }else{
                localStorage.removeItem("userToken")
                dispatch(changeStateValue({name: "loader", value:false}));
                dispatch(changeStateValue({name: "notAllowData", value:{position: true, type:'student'}}));
                return "error"
            }
        }else if(resp.data?.role == "parent" || resp.data?.role == "company_head" || resp.data?.role == "office_manager" || resp.data?.role == "content_manager" || resp.data?.role == "super_admin" || resp.data?.role == "accountant" || resp.data?.role == "auditor" ||  resp.data?.role == "support"){
            
            if(resp.data?.role == "accountant" || resp.data?.role == "super_admin" ||  resp.data?.role == "company_head"  ){
                celtgoLogin();
            }
            if( resp.data?.role == "company_head" || resp.data?.role == "office_manager" || resp.data?.role == "content_manager"){
                if(!resp?.data?.password_check){
                    localStorage.removeItem("userToken");
                    dispatch(changeStateValue({name: "loader", value:false}));
                    return "forgot";
                }
                dispatch(loginToPublicStaff({name: resp.data?.full_name, email: resp?.data?.email, role: resp?.data?.role, company_id: resp?.data?.company_id}))
            }
            if(resp.data?.role == "company_head" || resp.data?.role == "office_manager"){
                return dispatch(check2FAAuth())
                .then(respAuth=>{
                    
                    if(!respAuth?.success){
                        dispatch(changeStateValue({
                            name: 'user',
                            value: ""
                        }));
                        return "2FA"
                    }else{
                        
                        dispatch(changeStateValue({
                            name: 'user',
                            value: resp.data
                        }));
                        dispatch(changeStateValue({name: "loader", value:false}));
                        return "success"
                    }
                })
            }else{
                dispatch(changeStateValue({
                    name: 'user',
                    value: resp.data
                }));
            }
          
            dispatch(changeStateValue({name: "loader", value:false}));
            // storeLoginLogs(resp?.data?.id)
            return "success"
        }else if(resp.data?.role == "head_teacher" || resp.data?.role == "teacher" || resp.data?.role == "speaking_teacher"){
           
            if(!resp?.data?.password_check){
                localStorage.removeItem("userToken");
                dispatch(changeStateValue({name: "loader", value:false}));
                return "forgot";
            }
            if(resp?.data.teacher_lock === null){
                if(resp.data.lock_status || resp.data.manual_lock_status === 1){
                    localStorage.removeItem("userToken")
                    dispatch(changeStateValue({name: "notAllowData", value:{position: true, type:'teacher'}}));
                    return "error"
                }else{
                    dispatch(loginToPublicStaff({name: resp.data?.full_name, email: resp?.data?.email, role: resp?.data?.role, company_id: resp?.data?.company_id}))
                    dispatch(changeStateValue({
                        name: 'user',
                        value: resp.data
                    }));
                    dispatch(changeStateValue({name: "loader", value:false}));
                    // storeLoginLogs(resp?.data?.id)
                    return "success"
                }
            }else if(resp?.data.teacher_lock !== null){
                if(resp.data.teacher_lock.lock_status === 1){
                    localStorage.removeItem("userToken")
                    dispatch(changeStateValue({name: "notAllowData", value:{position: true, type:'teacher'}}));
                    return "error"
                }else{
                    dispatch(loginToPublicStaff({name: resp.data?.full_name, email: resp?.data?.email, role: resp?.data?.role, company_id: resp?.data?.company_id}))
                    dispatch(changeStateValue({
                        name: 'user',
                        value: resp.data
                    }));
                    dispatch(changeStateValue({name: "loader", value:false}));
                    // storeLoginLogs(resp?.data?.id)
                    return "success"
                }
            }
            
        }else{
            dispatch(changeStateValue({name: "loader", value:false}));
            return "error"
        }
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return "error"
    })
}


export const getHomeWorks = (id, keyword) => async dispatch => {
    
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exams`, {
        params: {page: '1', user_type:'student', student_id: id, home_work:1, keyword: keyword},
        headers: apiHeaders()
    }).then(resp=>{
       
        dispatch(changeStateValue({name: 'homeworks', value: resp?.data?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getStudentGPA = (id="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-gpa?student_id=${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}
export const getEssayReview = (id, page,  keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/essay-answers`, {
        params: {page: page, keyword: keyword, student_id: id, student_list: 1},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'essayReviews', value: resp?.data}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getSpeakingReview = (page,  keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/speakings`, {
        params:{keyword:keyword, query_type: 'student_answer', type: 'student', teacher_student_assign: 'teacher_student_assign', page: page},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'speakingReviews', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getExamResults = (id, page, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/studentAnswer`, {
        params: {keyword: keyword, student_id: id, type: 'exam_result', page: page, data_type : 'current'},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'examResults', value: resp?.data?.data}))
        return resp?.data?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}



export const getExamPartResults = (id, page, keyword, type) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/studentAnswer`, {
        params: {keyword: keyword, student_id: id, type: 'student-exam-results-parts', page: page, exam_type :type},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'examResults', value: resp?.data?.data}))
        return resp?.data?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}
export const getHwPartResults = (id, page, keyword, type) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/studentAnswer`, {
        params: {keyword: keyword, student_id: id, type: 'student-hw-results-parts', page: page, exam_type :type},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'hwResults', value: resp?.data?.data}))
        return resp?.data?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}




export const getHwResults = (id, page, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/studentAnswer`, {
        params:{student_id: id, keyword: keyword, type: 'home_work', page:page, data_type:'current'},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'hwResults', value: resp?.data?.data}))
        return resp?.data?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getCourses = (id="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/courses`, {
        params:{page:1, lesson_mode_id:id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'studentCourses', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getLessons = (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/lessons`, {
        params:{course_id:id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'lessons', value: resp?.data}))
        return resp.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getCoursesData = (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/courses/${id}`, {
        params:{page:1},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'coursesData', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getSpPracticeReviews = (id='', page, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-speaking-practice-student-review`, {
        params:{student_id:id, page, keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'speakingPracticeReviews', value: resp?.data}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getIalExReviews = (id='', page=1, keyword="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/a-level-review-student`, {
        params:{student_id:id, page:page, keyword:keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'ialExReviews', value: resp?.data}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getCertificates = (id, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/advanceds`, {
        params:{student_id: id, page: 1, keyword: keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'certificates', value: resp?.data}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getResources = () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/resources`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'resourcesData', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getSpeakingByCourse = (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/getSpeakingbyCourse/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        // console.log(resp.data)
        dispatch(changeStateValue({name: 'speakings', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getEssayByCourse = (id, studentId="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/lessons/${id}/essays?student_id=${studentId}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'essays', value: resp?.data?.data}))
        return resp?.data?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getSpPracticeByCourse = (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/speaking-practice?lesson_id=${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'spPractice', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getIALExams = (page=1,keyword="",type='', lessonId='', studentId='', active_type='') => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/new-essays?type=${type}&lesson_id=${lessonId}&student_id=${studentId}&active_type=${active_type}&page=${page}&keyword=${keyword}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'ialExams', value: resp?.data}))
        return resp.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getCheckins = (data) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/attendances`, {
        params: data,
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'checkins', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const getExamPartScore = (data) => async dispatch => {
    // console.log(data)
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-part-score`, {
        params: data,
        headers: apiHeaders()
    }).then(resp=>{
        // console.log(resp?.data)
        dispatch(changeStateValue({name: 'partScoreModal.data', value: resp?.data?.data}));
        return 'success'
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}

export const getSpeakingAnswer = (speakingId, studentId, answerId) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/getAnswerbySpeking/${speakingId}/${studentId}/${answerId}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return resp.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return 'error'
    })
}
export const getIALExamsById = (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/new-essays/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'ialExamsData', value: {...resp?.data, answer: resp?.data?.answers.length !==0?resp?.data?.answers[0]?.answer:'' }}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getExamStart = (exam_id,id) => async dispatch => {
    dispatch(changeStateValue({name: "loaderLong", value:true}));
    return axios.get(`${MAIN_APIV2}/student-exam/parent-question-ids/${exam_id}/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'examsData', value: resp.data}));
        dispatch(changeStateValue({name: "loaderLong", value:false}));
        return 'success';
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loaderLong", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getHWId = (exam_id,id) => async dispatch => {
    // console.log(id)
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_APIV2}/student-homework`, {
        params:{exam_id,student_id:id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return 'error'
    })
}
export const getExamId = (exam_id,id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_APIV2}/student-exam`, {
        params:{exam_id,student_id:id, type:'exam'},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return 'error'
    })
}
export const getExamData = (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exams/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        // dispatch(changeStateValue({name: 'loader', value: false}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}
export const getExams = (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exams`, {
        params:{keyword: '', user_type: 'student', student_id: id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'exams', value: resp?.data?.data}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}
export const getToeflExamData = (examId, id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/start-toefl-exam-writing?exam_id=${examId}&id=${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}
export const getToeflWritingExam = (exam_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/toefl-exam-writing?exam_id=${exam_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "toeflWritingExam", value:resp?.data}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}
export const getToeflSpeakingExam = (exam_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/toefl-exam-speaking?exam_id=${exam_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "studentToeflSpeakingExam", value:resp?.data}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}
export const getMocExamDetails = (exam_id, sections) => async dispatch => {
    // console.log(exam_id, sections)
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_APIV2}/student-moc-exam/parent-question-ids`, {
        params: {exam_id, sections},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "examsData", value:resp?.data}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}
export const getMockParentsDetail = (id) => async dispatch => {
    // console.log(exam_id, sections)
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-dynamic-parents/${id}`, {
        // params: {exam_id, sections},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return 'error'
    })
}


export const updateToeflExamWriting = (id, part, answer) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/update-toefl-exam-writing`,{id: id, part: part, answer: answer},{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "toeflWritingExam", value:resp?.data}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const updateToeflExamSpeaking = (id, part, answer) => async dispatch => {
    let formData = new FormData();
    formData.append('id', id); 
    formData.append('part', part);  
    formData.append('is_submite', '0');  
    if(answer != ''){
      formData.append('answer', answer, answer.name)
    }else{
      formData.append('answer', '')
    }
    
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/update-toefl-exam-writing`,formData,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "studentToeflSpeakingExam", value:resp?.data}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}


export const insertDataWithParams = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return {msg:"success", data:resp?.data}
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return {msg:"error", data: err.response}
    })
}
export const insertNewData = (url, formData, type) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        console.log(resp?.data)
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        if(type=="checkin"){
            bigAlert("Oops!", err.response.data.message,"error",)
        }else{
            dispatch(getErrors(err))
        }
        console.log(err.response)
        return 'error'
    })
}
export const deleteData = (url, data='') => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.delete(`${MAIN_API}/${url}?data=${data}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return 'error'
    })
}
export const insertNewDataV2 = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_APIV2}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
     
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}



// parent role actions

export const getParentStudents = () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/users?user_type=parent_student`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "parentStudents", value:resp?.data}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}
export const getStudentsCheckins = (date, parent_id,  teacher_id, company_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/attendances/report`, {
        params:{date: date, parent_id: parent_id, company_id: company_id, teacher_id: teacher_id, menu: false, page: 1},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "studentCheckIns", value:resp?.data}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}
export const getUnreadMessageCount = () => async dispatch => {
    return axios.get(`${MAIN_API}/messages`, {
        params:{type: 'unreade_messages'},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "unreadMsgCount", value:resp?.data}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ''
    })
}
export const getStudentsReview = (page, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/reviews-by-student`, {
        params:{page, keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(changeStateValue({name: "studentsReview", value:resp?.data}));
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ''
    })
}
export const checkEssayPlagiarism = (essay_id, other_essay_id, type, company_id, show=true) => async dispatch => {
    return axios.get(`${MAIN_API}/check-plagiarism`, {
        params:{essay_id: essay_id, other_essay_id: other_essay_id, type, company_id: company_id},
        headers: apiHeaders()
    }).then(resp=>{
        if(show){
            dispatch(changeStateValue({name: "essayPlagiarism", value:{
                position:true,
                value:resp?.data
            }})); 
        }
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return ''
    })
}

const getUncMsg=(arr)=>{
    let str = '';
    for(const data of arr){
      for(const property in data){
        str+= `${property}: ${data[property]} <br />\n\n`
      }
    }
    return str
}
export const checkStudentReminder = (data) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/check-student-reminder`, {
        params:data,
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "loader", value:false}));
        if(resp.data.data.length == 0){
            bigAlert('Exam can be unlocked!',  "Student can take the exam of this unit",  'success')
            return
        }
        Swal.fire(
            'Exam locked!',
            `To unlock the exam, you have to complete the following: <br /><br />
              ${getUncMsg(resp?.data?.data)}
            `,
            ''
          )
        return resp.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return ''
    })
}



