import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCoursesData, getLessons } from '../../actions/MainAction';
import { withHooks } from '../../actions/withHooks';
import Header from '../../components/header/Header';
import Card3 from '../../components/cards/Card3'
import EmptyPage from '../../components/EmptyPage';
import HeaderButtons from '../../components/HeaderButtons';
import { changeStateValue } from '../../redux/MainReducer';
import { deleteData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
export class AdminListeningData extends Component {
    componentDidMount(){
        this.props.getCoursesData(this.props.params.id);
        window.scrollTo(0,0)
    }
    addBtn=(data="")=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                course_id:this.props.coursesData?.id,
                lesson_id:data?.lesson_id? data?.lesson_id:'',
                title:data?.title? data?.title:'',
                audio_file:data?.audio_file? data?.audio_file:'',
                audio_full_url:data?.audio_file_real_path? data?.audio_file_real_path:'',
                video_file:data?.video_file? data?.video_file:'',
                video_full_url:data?.video_file_real_path? data?.video_file_real_path:'',
                

                afterCallFunction:this.props.getCoursesData,
                afterCallParams:[this.props.params.id],
                requireds:["lesson_id", "title"],
                url: data?.id? `listenings/${data?.id}`:'listenings',
                modalTitle: data?.id? `Edit Listening`:'Add Listening',
                overflow_def:true
            }
        });
        this.props.getLessons(this.props.params.id)
    }

    deleteData=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`listenings/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getCoursesData(this.props.params.id);
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {coursesData} = this.props;
    // console.log(coursesData?.listenings)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={coursesData?.title} />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add a Listening", id:'0'}]} selected={0} />
            </div>
            {coursesData?.listenings?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-320'>
                {
                    coursesData?.listenings?.map((data, i)=>{
                        return(
                            <div key={i} className='bg-white rounded-[20px]'>
                                <div   className={`bg-white rounded-full p-[7px] ${data?.audio_file? "items-center":"flex-col items-start"} flex  gap-[10px]`}>
                                    <span className='h-[36px] pl-[7px] pr-[7px] min-w-[36px]  inline-flex justify-center items-center txt-blue font-medium text-[16px] bg-light-blue rounded-full'>{data?.title}</span>
                                    {
                                        data?.audio_file?
                                        <audio className='h-[36px] listening-audio' src={data?.audio_file_real_path} controls />:
                                        <video src={data?.video_file_real_path} className='w-full' controls />
                                    }
                                </div>
                                <div className='flex justify-between p-[10px]'>
                                    <button onClick={this.deleteData.bind(this, data?.id)} className='bg-red text-[12px] text-white p-[3px] rounded-[4px] '>Delete</button>
                                    <button onClick={this.addBtn.bind(this, data)} className='bg-blue text-[12px] text-white p-[3px] rounded-[4px] '>Edit</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    coursesData: state.Data.coursesData
});
const mapDispatchToProps = {getCoursesData, changeStateValue, getLessons, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminListeningData))


