import React, { Component } from 'react'
import { getAdminLessonModes, getPriceScheme } from '../../actions/AdminActions';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import HeaderButtons from '../../components/HeaderButtons';
import { changeStateValue } from '../../redux/MainReducer';
import Card1 from '../../components/cards/Card1';
import { deleteData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';

export class AdminPriceSchemePage extends Component {
    componentDidMount(){
        this.props.getPriceScheme();
        // this.props.getAdminLessonModes()
    }
    getLessonModes(data){
      let arr = [];
      for(const item of data){
        arr.push({label: item?.lesson_mode?.title, value:item?.lesson_mode_id})
      }
      return arr;
    }
    getCourses(data){
      let arr = [];
      for(const item of data){
        arr.push({label: item?.course?.title, value:item?.course?.id})
      }
      return arr;
    }
    addBtn=(data="")=>{
      this.props.changeStateValue({
        name:'adminModalData',
        value: {
            position: true,
            id:data?.id? data?.id:'',
            title:data?.title? data?.title:'',
            lesson_time:data?.lesson_time? data?.lesson_time:'',
            price:data?.price? data?.price:'',
            total_hours:data?.total_hours? data?.total_hours:'',
            total_lesson:data?.total_lesson? data?.total_lesson:'',
            total_month:data?.total_month? data?.total_month:'',
            optional_times:data?.optional_times? data?.optional_times:'',
            group_type:data?.group_type? data?.group_type:'',

            afterCallFunction:this.props.getPriceScheme,
            afterCallParams:[],
            requireds:["title", "lesson_time", "price", ],
            url: data?.id? `price-scheme/${data?.id}`:'price-scheme',
            modalTitle: data?.id? `Edit Price`:'Add Price',
            courses:data==""?[]: this.getCourses(data?.course)
        }
    });
    }
    onDeleteBtn=(id)=>{
      confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
      .then(alertResp=>{
          if(alertResp === "success"){
          this.props.deleteData(`price-scheme/${id}`)
          .then(resp=>{
              if(resp == "success"){
                  this.props.getPriceScheme()
                  mixinAlert("success", "Data deleted successfully")
              }else{
                  mixinAlert("error", "Something went wrong")
              }
          })
          }
      })
    }
  
  render() {
    const {priceSchemeData, adminLessonModes} = this.props;
    // console.log(priceSchemeData)
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title="Price Scheme" />
        <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
            <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add a Price", id:'0'}]} selected={0} />
        </div>
        <div className='mt-[30px] grid-full-250 items-start'>
            {
              priceSchemeData?.map((data, i)=>{
                return (
                  <Card1
                    key={i}
                    statusBisible={false}
                    status={1}
                    btn={false}
                    title={data?.title}
                    grade={data?.price}
                    exam={data?.group_type}
                    btn2={true}
                    btn2Text={"Edit"}
                    btn2Color={"bg-blue"}
                    adminDelete={true}
                    onCLickBtn={this.addBtn.bind(this, data)}
                    onDeleteBtn={this.onDeleteBtn.bind(this, data?.id)}
                    // moreOption={this.getLessonModes(data?.lesson_mode)}
                  />
                )
              })
            }
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    priceSchemeData: state.Data.priceSchemeData,
    adminLessonModes: state.Data.adminLessonModes,
});
const mapDispatchToProps = {getPriceScheme, getAdminLessonModes, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminPriceSchemePage)
