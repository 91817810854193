import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getAdminExamResults } from '../../actions/AdminActions';
import { getCompanies } from '../../actions/AdminActions';
import DropSearch from '../../components/DropSearch';
import { getDropValue, getSATScore } from '../../actions/getRole';
import MonthlyCalendar from '../../components/MonthlyCalendar';
import HeaderButtons from '../../components/HeaderButtons';
import moment from 'moment';
import Search from '../../components/Search';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Card1 from '../../components/cards/Card1';
import { changeStateValue } from '../../redux/MainReducer';
import { getSingleData } from '../../actions/StaffAction';
export class AdminExamResults extends Component {
    state={
        company_id:'',
        selected:0,
        date:moment(new Date()).format("YYYY-MM"),
        resultType:'',
        page:1,
        search:''
    }
    componentDidMount(){
        this.props.getCompanies(10, '')
        this.props.changeStateValue({
            name:'adminExamResults',
            value: []
        });
        this.props.getAdminExamResults(this.state.page, this.state.date, this.state.resultType, this.state.search, '',"super-admin-exam-result")
    }
    selectCompany=(id)=>{
        this.setState({company_id:id})
        this.props.getAdminExamResults(this.state.page, this.state.date, this.state.resultType, this.state.search, id,"super-admin-exam-result")
    }
    getDataByType=(data, index)=>{
        this.setState({selected:index, resultType:data?.id});
        this.props.getAdminExamResults(this.state.page, this.state.date, data?.id, this.state.search, this.state.company_id,"super-admin-exam-result")
    }
    selectMonth=(date)=>{
        this.setState({date})
        this.props.getAdminExamResults(this.state.page, date, this.state.resultType, this.state.search, this.state.company_id,"super-admin-exam-result")
    }
    onSearchData=(search)=>{
        this.setState({search});
        this.props.getAdminExamResults(this.state.page, this.state.date, this.state.resultType, search, this.state.company_id,"super-admin-exam-result")
    }
    loadMore=(page)=>{
        this.setState({page});
        this.props.getAdminExamResults(page, this.state.date, this.state.resultType, this.state.search, this.state.company_id,"super-admin-exam-result")
    }
    getPlagiarism(data){
        let cases = [];
        if(data){
            data.map((pl, i)=>{
                cases.push(+pl.case)
            })
            if(cases.length !== 0){
                if(Math.max(...cases) == 3){
                    return '90%';
                }else if(Math.max(...cases) == 1){
                    return '40%';
                }else if(Math.max(...cases) == 0){
                    return '0%';
                }
            }else{
                return '0%';
            } 
        }else{
            return '0%';
        } 
        
    }
    onClickAnswer=(id)=>{
        this.props.getSingleData(`student-pooling-answers?exam_id=${id}`)
        .then(resp=>{
          this.props.changeStateValue({
            name:"studentPoolingAnswersModal",
            value:{
              position: true,
              data: resp
            }
          })
        })
      }
  render() {
    const {adminCompanies, adminExamResults, lang, user} = this.props;
    const {company_id, selected} = this.state;
    const btns = [
        {
            title: "All",
            id: ""
        },
        {
            title: "Failed",
            id: "Failed"
        },
        {
            title: "Passed",
            id: "Passed"
        },
    ]
    
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Exam Results" />
            <div className='mt-[30px]  white-search flex justify-start items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <DropSearch selectData={this.selectCompany.bind(this)} value={company_id} placeholder="Select a Company" options={getDropValue(adminCompanies, "name","id")}  />
                <MonthlyCalendar onSelect={this.selectMonth.bind(this)} bgCont="bg-none" bg="bg-white" />
                <HeaderButtons  onClick={(data, index)=>this.getDataByType(data, index)} buttons={btns} selected={selected} />
            </div>
            <div className='flex justify-center '>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>

            <div className='mt-[42px] grid-full-250'>
                {
                  adminExamResults?.map((data, i)=>{
                    return(
                      <Card1
                        key={i}
                        titleLink={`/students/${data?.student?.id}`}
                        title={data?.student?.full_name}
                        exam={data?.exam?.title}
                        status={data?.status}
                        time={data?.spend_time}
                        grade={getSATScore(data.exam_title, +data.score, data?.exam?.course_id == 134 && data?.exam?.exam_type == "pooling_standart"?"ielts":"normal")}
                        statusText={data?.status == 1? lang?.pass: lang?.fail}
                        date={moment(data?.updated_at).format("HH:mm, DD/MM/YY")}
                        cheatingExam={this.getPlagiarism(data.plagiarism)}
                        btn={false}
                        url={`/admin-exam-results/${data?.id}/reviews`}
                        blank={true}
                        answerBtn={(user?.role === "company_head" || user?.role == "super_admin") && (data?.exam?.exam_type === "pooling_standart" || data?.exam?.exam_type === "pooling_dynamic")?  true :false}
                        onClickAnswer={this.onClickAnswer.bind(this, data?.id)}
                      />
                    )
                  })
                }
            </div>
            {
                adminExamResults?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={adminExamResults?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminExamResults: state.Data.adminExamResults,
    adminCompanies: state.Data.adminCompanies,
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {getAdminExamResults, getCompanies, changeStateValue, getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminExamResults)
