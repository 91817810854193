export const MAIN_API = 'https://api-lms.celt.vip/api/v1';
export const MAIN_APIV2 = 'https://api-lms.celt.vip/api/v2';

// export const MAIN_API = 'https://phplaravel-1197107-4222269.cloudwaysapps.com/api/v1'; //stages
// export const MAIN_APIV2 = 'https://phplaravel-1197107-4222269.cloudwaysapps.com/api/v2'; //stage

export const PUBLIC_API = 'https://game.celt.vip/api/v1';

export const CELTGO_API = 'https://goapi.celt.vip/api/v1';

export const SOCKET_API = 'https://whale-app-y7eoh.ondigitalocean.app'