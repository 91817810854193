import React, { Component } from 'react'
import Profile from '../../components/dashboard/Profile'
import TodoCards from '../../components/dashboard/TodoCards'
import { connect } from 'react-redux';
import { getHomeWorks, getEssayReview, getSpeakingReview, getSpeakingAnswer } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import { changeStateValue } from '../../redux/MainReducer';
import { withHooks } from '../../actions/withHooks';
import { getRole } from '../../actions/getRole';
import { getStudentCoach, getStudentWeeklyCoach } from '../../actions/CoachAction';
import StudentCoachContainer from '../../components/dashboard/StudentCoachContainer';
import ExamSchedule from '../../components/dashboard/ExamSchedule';
import moment from 'moment';
import { getExamSchedule } from '../../actions/StaffAction';
import LinksCard from '../../components/dashboard/LinksCard';
import FairModal from '../../components/FairModal';
export class StudentDashboard extends Component {

    componentDidMount(){
        if(localStorage.getItem('userToken')){
            const data = jwtDecode(localStorage.getItem('userToken'));
            this.props.getHomeWorks(data.sub);
            this.props.getEssayReview(data.sub, 1);
            this.props.getSpeakingReview(1);
        }
        window.scrollTo(0,0);
        this.props.getStudentCoach()
        this.props.getStudentWeeklyCoach()
        this.props.getExamSchedule(moment(new Date()).format("YYYY-MM-DD"));

       
    }

    openHWModal=(data)=>{
       this.props.navigate(`/hw-start/${data?.data?.exam?.id}`)
    }
    openEssayModal=(item)=>{
        let data = item?.data
        const params = {
            position:true,
            type:'essay',
            modalType: 'review',
            title: 'QUESTIONS',
            question: data?.question ? `<pre class='generatedEssayStyle'>${data?.question} <br /><hr /> <br />${data?.outline}</pre>`: data?.essay?.question,
            essay: data?.answer,
            reviews: data?.reviews[0]?.review,
            teacher: data?.reviews[0]?.user?.full_name,
            role: getRole(data?.reviews[0]?.user?.role, this.props.lang),
            stuent: data?.user?.full_name,
            robot_review:data?.robot_review,
            grade: data?.grade,
            model_answer: data?.model_answer?.answer,
        }
        this.props.changeStateValue({name: 'mainModal', value: params});
    }
    openSpeakingModal=(item)=>{
        let data = item?.data
        let check = 0;
        if(data.text){
            check = data.text.split(' ')?.length;
        }
        this.props.getSpeakingAnswer(data?.speaking?.id, data?.user_id, data?.id)
        .then(resp=>{
            if(resp != 'error'){
                const params = {
                    position:true,
                    type:'speaking',
                    modalType: 'review',
                    title: resp?.title,
                    question: resp?.question,
                    speaking: resp?.answare[0]?.image_full_url,
                    reviews: resp?.reviews[0]?.review,
                    teacher: resp?.reviews[0]?.user?.full_name,
                    role: getRole(resp?.reviews[0]?.user?.role, this.props.lang),
                    stuent: data?.user?.full_name,
                    robot_review: check>=30? data?.robot_review:null,
                    grade: resp?.reviews[0]?.grade,
                }
                this.props.changeStateValue({name: 'mainModal', value: params});
            }
        })
    }
  render() {
    const {homeworks, essayReviews, speakingReviews, lang, studentCoach, examSchedule, groupsLinks, studentWeeklyCoach} = this.props;
    const hw = [];
    const essay = [];
    const speaking = [];
    homeworks.forEach((data, i)=>{
        if(i<7){
            hw.push({
                title: data?.exam?.title,
                data: data
            })
        }
    });
    essayReviews.forEach((data, i)=>{
        if(i<7){
            essay.push({
                title: data?.essay?.title,
                data
            })
        }
    });
    speakingReviews.forEach((data, i)=>{
        if(i<7){
            speaking.push({
                title: data?.speaking?.title,
                data
            })
        }
    });
    // console.log(studentWeeklyCoach)
    // console.log(studentCoach)
    return (
        <div className="grid grid-full-440 gap-[30px] max-[1100px]:mt-[120px] max-[800px]:mt-[150px]">
            <Profile studentCoach={studentCoach} />
            {
                groupsLinks.length !=0?
                <LinksCard />:null
            }
            
            {/* <Resutls /> */}
            {
                studentWeeklyCoach?.map((data, i)=>{
                    return(
                        <StudentCoachContainer title="Weekly" data={data} key={i} />
                    )
                })
            }
            {
                studentCoach?.map((data, i)=>{
                    return(
                        <StudentCoachContainer title="Monthly" data={data} key={i} />
                    )
                })
            }
            
            {/* <TodoCards openModal={this.openHWModal.bind(this)} array={hw} title={lang?.hw_todo} path="/homework" /> */}
            {
                essay.length !=0?
                <TodoCards openModal={this.openEssayModal.bind(this)} array={essay} title={lang?.ess_rev} path="/essay-reviews" />:null
            }
            {
                speaking.length !=0?
                <TodoCards openModal={this.openSpeakingModal.bind(this)} array={speaking} title={lang?.sp_reviews} path="/speaking-reviews" />:null
            }
            {
                examSchedule?.map((data, i)=>{
                    return(
                        <ExamSchedule examSchedule={data} key={i} />
                    )
                })
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    homeworks: state.Data.homeworks,
    essayReviews: state.Data.essayReviews,
    speakingReviews: state.Data.speakingReviews,
    lang: state.Data.lang,
    studentCoach: state.Data.studentCoach,
    examSchedule: state.Data.examSchedule,
    groupsLinks: state.Data.groupsLinks,
    studentWeeklyCoach: state.Data.studentWeeklyCoach,
});
const mapDispatchToProps = {getHomeWorks, getEssayReview, getSpeakingReview, changeStateValue, getSpeakingAnswer, getStudentCoach, getExamSchedule, getStudentWeeklyCoach}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentDashboard))
