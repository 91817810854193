import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../../../redux/MainReducer';
import { connect } from 'react-redux';
import { getEnrollData, getGroupsByTeacher, getGroupUsers, getOldestEnrollCount, getStudentGroupData } from '../../../../actions/StaffAction';
import { mixinAlert } from '../../../../actions/SweetAlerts';
import InfoSvg from '../../../../components/svg/InfoSvg';
import GroupUsers from '../../../../components/modals/GroupUsers';
import Header from '../../../../components/modals/Header';
import { getPriceScheme } from '../../../../actions/AdminActions';

export class GroupSelect extends Component {
    state={
        group_type:'',
    }

    selectGroup=(type)=>{
        this.props.changeStateValue({
            name:"studentCalculatorModal.group_type",
            value: type
        })
        this.setState({group_type:type})
    }
    componentDidMount(){
        this.props.changeStateValue({ name:"priceSchemeData", value:[] })
        this.props.resetStateValue({ name:"oldestEnrollCount" })
    }
    onSelectGroup=(id, price_id)=>{
        this.props.changeStateValue({
            name:"studentCalculatorModal.student_group_id",
            value:id
        })
        if(price_id){
            this.props.changeStateValue({
                name:"studentCalculatorModal.price_id",
                value:price_id
            });

        }
        this.props.getStudentGroupData(this.props.studentCalculatorModal?.teacher_id, id)
        .then(resp=>{
            this.props.changeStateValue({ name:"studentCalculatorModal.course_id", value:resp?.course_id?resp?.course_id:'' })
            this.props.changeStateValue({ name:"studentCalculatorModal.lesson_mode", value:resp?.lesson_mode?resp?.lesson_mode :'' })
            this.props.changeStateValue({ name:"studentCalculatorModal.study_mode", value:resp?.study_mode?resp?.study_mode:'' })
            this.props.changeStateValue({ name:"studentCalculatorModal.lesson_mode_id", value:resp?.lesson_mode_id?resp?.lesson_mode_id:'' })
            this.props.changeStateValue({ name:"studentCalculatorModal.lesson_hour", value:resp?.lesson_houre?resp?.lesson_houre:'' })
            this.props.changeStateValue({ name:"studentCalculatorModal.group_type", value:resp?.lesson_mode? "exists":"new"})
            this.props.getEnrollData(resp?.lesson_mode_id, resp?.study_mode, this.props.student?.id)
            
            if(price_id && resp?.course_id){
                this.props.getPriceScheme(resp?.course_id);
                this.props.changeStateValue({ name:"studentCalculatorModal.selected_count", value: 12})
                this.props.changeStateValue({ name:"studentCalculatorModal.count", value: 12})
            }
            


            if(resp?.lesson_mode_id){
                this.props.getOldestEnrollCount(resp?.lesson_mode_id, this.props.student?.id)
                .then(resp=>{
                   
                    this.props.changeStateValue({name:"studentCalculatorModal.selected_count", value:""})
                    this.props.changeStateValue({name:"studentCalculatorModal.price_type", value:""})
                    this.props.changeStateValue({name:"studentCalculatorModal.fee", value:""})
                
                    
                    if(!resp?.id || resp?.id ==""){
                        this.props.changeStateValue({name:"studentCalculatorModal.selected_count", value:this.props.studentCalculatorModal?.count})
                        this.props.changeStateValue({name:"studentCalculatorModal.price_type", value:"new"})
                    }
                    // if(resp?.id && resp?.id !=""){
                    //   if(resp?.file){
                    //     this.props.changeStateValue({name:"studentCalculatorModal.file", value:resp?.file})
                    //     this.props.changeStateValue({name:"studentCalculatorModal.file_full_url", value:resp?.file_full_url})
                    //   }else if(resp?.image){
                    //     this.props.changeStateValue({name:"studentCalculatorModal.image", value:resp?.image})
                    //     this.props.changeStateValue({name:"studentCalculatorModal.image_full_url", value:resp?.image_full_url})
                    //   }
                     
                    // }
                  })
            }
        })

        
        
    }
    changePage=(page)=>{
        if(page === "enroll_params" || page === "new_enroll_params"){
            if(this.props.studentCalculatorModal.student_group_id ==""){
                mixinAlert("warning", "Please, select a course");
                return;
            }
        }
        this.props.changeStateValue({
            name:"studentCalculatorModal.page",
            value:page
        })
    }
    getGroupInfo=(data)=>{
        // console.log(data)
        this.props.getGroupUsers(data?.mode?.teacher_id, data?.id)
    }

    selectNewGroup(groupsByTeacher){
        if(groupsByTeacher.filter(x=>x.count == 0)?.length != 0){
            this.onSelectGroup(groupsByTeacher.filter(x=>x.count == 0)[0]?.id)
        }else{
            mixinAlert("warning", "There is not any empty group to enroll")
        }
        this.setState({group_type:"new"});
    }
  render() {
    const {group_type} = this.state;
    const {groupsByTeacher, studentCalculatorModal, studentGroupParams, studentGroupUsers} = this.props;
    // console.log(groupsByTeacher)
    return (
        <div className='bg-white w-[700px] shadow rounded-[10px] max-[730px]:ml-[20px] max-[730px]:mr-[20px]'>
            <Header title="Select a group" closeBtn={()=>this.props.onClose()} />
            {/* <h2 className='text-center txt-blue font-semibold text-[24px]'>Select a group</h2> */}
           <div className='p-[20px]'>
            <div className='grid grid-cols-2 gap-[20px] mt-[20px]'>
                    <button onClick={this.selectGroup.bind(this, "exists")} className={`${studentCalculatorModal?.group_type === "exists"? "bg-blue text-white":"border border-[var(--blue)] txt-blue "} text-[14px] font-semibold h-[40px] rounded-[10px]`}>
                        Existing group
                    </button>
                    <button onClick={this.selectNewGroup.bind(this, groupsByTeacher)} className={`${studentCalculatorModal?.group_type === "new"? "bg-blue text-white":"border border-[var(--blue)] txt-blue "} text-[14px] font-semibold h-[40px] rounded-[10px]`}>
                        New group
                    </button>
                </div>
                <div className='grid-full-100 mt-[20px] max-h-[60vh] overflow-auto'>
                    {
                        groupsByTeacher?.filter(data => (studentCalculatorModal?.enroll_type === "new" && data?.mode?.price_id) || (studentCalculatorModal?.enroll_type === "old" && !data?.mode?.price_id))?.map((data, i)=>{
                            if(studentCalculatorModal?.group_type === "exists"){
                                if(data?.count !== 0){
                                    return(
                                        <div className='flex flex-col-reverse'>
                                            <button onClick={this.onSelectGroup.bind(this, data?.id, data?.mode?.price_id)} className={`${studentCalculatorModal?.student_group_id === data?.id? "bg-blue text-white":"txt-blue "} h-[35px] p-[0px_10px] inline-flex items-center text-[14px] border border-[var(--blue)] rounded-[10px] font-semibold justify-center`} key={i}>
                                                {data?.title}
                                            </button>
                                            <button onClick={this.getGroupInfo.bind(this, data)}>
                                                <InfoSvg />
                                            </button>
                                        </div>
                                    )
                                    
                                }
                            }
                            // else if(group_type === "new"){
                            //     if(data?.count === 0){
                            //         return(
                            //             <button  onClick={this.onSelectGroup.bind(this, data?.id)} className={`${studentCalculatorModal?.student_group_id === data?.id? "bg-blue text-white":"txt-blue "} h-[35px] p-[0px_10px] inline-flex items-center text-[14px] border border-[var(--blue)] rounded-[10px] font-semibold justify-center`} key={i}>
                            //                 {data?.title}
                            //             </button>
                            //         )
                            //     }
                                
                            // }
                        })
                    }
                    
                </div>
                {
                        studentCalculatorModal?.group_type === "exists" &&   groupsByTeacher?.filter(data =>data?.count !==0 && (studentCalculatorModal?.enroll_type === "new" && data?.mode?.price_id) || (studentCalculatorModal?.enroll_type === "old" && !data?.mode?.price_id)).length ===0?
                        <p className='text-center font-[600] txt-blue text-[18px]'>
                            Not Exists Group. Please select New Group
                        </p>:null
                    }
                <div className='flex mt-[20px] justify-between'>
                    <button onClick={this.changePage.bind(this, "enroll_type")} className="h-[25px] bg-orange p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                        Back
                    </button>
                    <button onClick={this.changePage.bind(this, studentCalculatorModal?.enroll_type === "new"?"new_enroll_params": "enroll_params")} className="h-[25px] bg-blue p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                        Save & next
                    </button>
                </div>
           </div>
            {studentGroupUsers?.length !=0? <GroupUsers />:null}
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentCalculatorModal: state.Data.studentCalculatorModal,
    groupsByTeacher: state.Data.groupsByTeacher,
    studentGroupParams: state.Data.studentGroupParams,
    studentGroupUsers: state.Data.studentGroupUsers,
});
const mapDispatchToProps = {changeStateValue, getStudentGroupData, resetStateValue, getEnrollData, getGroupUsers, getOldestEnrollCount, getPriceScheme}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSelect)
