import React, { Component } from 'react'
import ExamRightSvg from '../../../components/svg/ExamRightSvg'
import Countdown, {zeroPad} from 'react-countdown';
import { mixinAlert } from '../../../actions/SweetAlerts';

export class ExamHeader extends Component {

  state={
    btnActiveDisable:false
  }
  renderer( { hours, minutes, seconds, completed}){
      // console.log("fdff", hours, minutes, seconds)
    //   let exam = new Date(this.props.timer).getTime() + 30 * 60 * 1000;
    //   let now = new Date().getTime();
    //   if(now >= exam){
    //     if(!this.state.btnActiveDisable){
    //         this.setState({btnActiveDisable: true})
    //     }
    // }
    if(!completed){
        if(hours === 0 && minutes === 2 && seconds === 0 && window.location.pathname.split('/').includes('start-pooling-exams')){
            mixinAlert("info", "You have 2 minutes left. Please submit your exam. After 2 minutes, the system will lock the exam and you will get 0 score. Nothing will be saved. Please submit now")
        }
      return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    }else{
        return <span className='txt-blue text-[20px] font-bold'>00:00:00</span>
    }
}

stopTimer(){
    if( window.location.pathname.split('/').includes('start-pooling-exams')){
        // this.props.navigate("/exam-results");
        this.props.onClickRight("submit", "not_time")
        // this.submitExamData(this.state.examId)
    }
}
  render() {
    const {onClickRight=()=>{}, timer="", minute, title, btnNext=false, btnDisable=false, nextbtnVisible=true} = this.props;
    const {btnActiveDisable} = this.state;
    // console.log(timer, minute)
    return (
      <div className='bg-white h-[100px] border shadow flex items-center justify-between pl-[20px] pr-[20px] fixed w-[calc(100%_-_53px)] max-[1100px]:w-[calc(100%_-_54px)]'>
        <h3 className='font-bold txt-blue text-[28px]'>{title}</h3>
        {
            timer != ''?
            <span className='txt-blue text-[20px] font-bold text-center'>Time: {` `}
                <Countdown date={new Date(timer).getTime() + (minute)*60000} renderer={this.renderer.bind(this)}
                    onComplete={this.stopTimer.bind(this)}>
                </Countdown>
                {/* <p className='txt-red'>You should spent at least 30 minutes before submitting.</p> */}
            </span>:<span className='txt-blue text-[20px] font-bold'>00:00:00</span>
        }
        {}
        {nextbtnVisible?
          btnNext?
          <button disabled={btnDisable} onClick={()=>onClickRight("submit")} className={`bg-blue  text-white h-[35px] pl-[9px] pr-[9px] rounded-[7px] `}>
              Save and Submit
          </button>:
          <button disabled={btnDisable} onClick={()=>onClickRight("not_submit")} className='p-[5px_10px] bg-blue text-white text-[14px] rounded-[5px]'>
            {/* <ExamRightSvg /> */}
            Save & Next
        </button>:<div></div>
        }
        
      </div>
    )
  }
}

export default ExamHeader
