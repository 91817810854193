import React, { Component } from 'react'
import Header from '../../../../components/modals/Header'
import { changeStateValue } from '../../../../redux/MainReducer';
import { connect } from 'react-redux';
import { mixinAlert } from '../../../../actions/SweetAlerts';

export class Enrolltype extends Component {
    changePage=(page)=>{
        if(page === "new_enroll"){
            if(this.props.studentCalculatorModal.enroll_type ==""){
                mixinAlert("warning", "Please, select a type");
                return;
            }
        }
        this.props.changeStateValue({
            name:"studentCalculatorModal.page",
            value: page
        })
    }
  render() {
    const {studentCalculatorModal} = this.props;
    return (
        <div className='bg-white w-[700px] shadow rounded-[10px] max-[730px]:ml-[20px] max-[730px]:mr-[20px]'>
            <Header title="Select a enroll type" closeBtn={()=>this.props.onClose()} />
            <div className='p-[20px]'>
                <div className='grid grid-cols-2 gap-[20px] mt-[20px]'>
                     <button onClick={()=>this.props.changeStateValue({name:"studentCalculatorModal.enroll_type", value: "new"})} className={`${studentCalculatorModal?.enroll_type === "new"? "bg-blue text-white":"border border-[var(--blue)] txt-blue "} text-[14px] font-semibold h-[40px] rounded-[10px]`}>
                        New Enroll
                    </button>
                    <button onClick={()=>{this.props.changeStateValue({name:"studentCalculatorModal.enroll_type", value: "old"});this.props.changeStateValue({name:"studentCalculatorModal.price_id", value: ""})}} className={`${studentCalculatorModal?.enroll_type === "old"? "bg-blue text-white":"border border-[var(--blue)] txt-blue "} text-[14px] font-semibold h-[40px] rounded-[10px]`}>
                        Old Enroll
                    </button>
                   
                </div>
                <div className='flex mt-[20px] justify-between'>
                    <button onClick={this.changePage.bind(this, "teacher")} className="h-[25px] bg-orange p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                        Back
                    </button>
                    <button onClick={this.changePage.bind(this, "group")} className="h-[25px] bg-blue p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                        Save & next
                    </button>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentCalculatorModal: state.Data.studentCalculatorModal
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Enrolltype)
