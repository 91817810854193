import React, { Component } from 'react'
import CardUserSvg from '../svg/CardUserSvg'
import CardTimeSvg from '../svg/CardTimeSvg'
import CardPointSvg from '../svg/CardPointSvg'
import CardDateSvg from '../svg/CardDateSvg'
import CardLine from './CardLine'
import CardGroupSvg from '../svg/CardGroupSvg'
import { Link } from 'react-router-dom'
import CardCopySvg from '../svg/CardCopySvg'
import CardCloseSvg from '../svg/CardCloseSvg'
import CardLineBtn from './CardLineBtn'
import CardCheatingSvg from '../svg/CardCheatingSvg'
import CardExamSvg from '../svg/CardExamSvg'
import Countdown, {zeroPad} from 'react-countdown';
import CardEmailSvg from '../svg/CardEmailSvg'
import CardPhoneSvg from '../svg/CardPhoneSvg'
import GenEngCont from './GenEngCont'
import SatCont from './SatCont'
import TitleComplete from './TitleComplete'
import { connect } from 'react-redux'
import CardTypeSvg from '../svg/CardTypeSvg'
import CheckSvg from '../svg/CheckSvg'
import CardCertSvg from '../svg/CardCertSvg'
import CardCourseSvg from '../svg/CardCourseSvg'
import CardCourseTitleSvg from '../svg/CardCourseTitleSvg'
import CardLessonModeSvg from '../svg/CardLessonModeSvg'
import CardKeySvg from '../svg/CardKeySvg'
import CardLessonSvg from '../svg/CardLessonSvg'

export class Card1 extends Component {
  renderer( { hours, minutes, seconds, completed}){
    if(!completed){
      return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    }else{
      return <span>00:00:00</span>
    }
  }
  stopTimer(){
   
  }
  render() {
    const {title='', date='', grade='',grade2='', status='', statusText='', name='', time='', btn=true,  onCLickBtn=()=>{}, statusBisible=true, group="", url="",
          copy="", close="", disabled=false,  onLineClick=()=>{}, cheating="", exam="",date2="", timer="", minute=0, btn2=false, btn2Color="", btn2Text="", email="", phone="",user="",
          cheatingExam="",  gEng=false, essay="", speaking="", gExam="", gHw="", satExam="", satHw="",satVerbExam="",satVerbHw="", sat=false, showEmptyCard=false, blank=false, lang, activeStatus=1, 
          adminActiveVisible=false, onClickChangeStatus=()=>{}, adminDelete=false, onDeleteBtn=()=>{}, generateCert=()=>{}, titleLink="", userLink="", course="", 
          greenStatus=false, cheatingColor=false, reCheckBtn=false, onReCheck=()=>{}, cheatingColor2, cheating2="", adminLogBtn=false, adminLogOnclick=()=>{}, 
          adminZeroBtn=false, adminZeroClick=()=>{}, moreOption=[], canOpen=false, timer2="", timer2Text="", authCode, onGenerate=()=>{}, answerBtn=false, onClickAnswer=()=>{}, lesson=""} = this.props;
          // console.log(canOpen)
    return (
      <div className='bg-white rounded-[12px] pt-[18px] pl-[9px] pr-[9px] pb-[18px]'>
            <div className='flex justify-between items-center '>
              {
                title !==""?
                <TitleComplete titleLink={titleLink} title={title} />:null
              }
                
                {statusBisible?
                  status == 1?
                  <span className='pl-[12px] pr-[12px] pt-[3px] pb-[3px] rounded-[3px] bg-blue text-white text-[10px] font-semibold'>{statusText!= ''?statusText:lang?.replied}</span>:
                  status == 0?
                  <span className={`pl-[12px] pr-[12px] pt-[3px] pb-[3px] rounded-[3px] ${greenStatus?'bg-green-600':'bg-red'}  text-white text-[10px] font-semibold`}>{statusText!= ''?statusText: greenStatus?lang?.new: lang?.waiting}</span>:
                  status == 2?
                  <span className='pl-[12px] pr-[12px] pt-[3px] pb-[3px] rounded-[3px] bg-gray-400 text-white text-[10px] font-semibold'>{statusText!= ''?statusText:lang?.waiting}</span>:null:null
                }
                {
                  adminActiveVisible?
                   <button onClick={()=>onClickChangeStatus(activeStatus)}>
                    {activeStatus==1? <CheckSvg />:<CardTypeSvg /> }
                  </button>:null
                }
            
            </div>
            <ul className='mt-[34px] ml-[22px] mr-[22px] flex flex-col gap-[10px]'>
              {  email !== ""? <CardLine rounded={true} svg={<CardEmailSvg />} title={email} />:null  }
              { phone !== ""? <CardLine rounded={true} svg={<CardPhoneSvg />} title={phone} />:null  }
              { exam!== ''? <CardLine svg={<button onClick={()=>{generateCert()}}><CardExamSvg /></button>} title={exam} />:null  }
              { course!== ''?  <CardLine svg={<CardCourseTitleSvg />}  title={course} />:null }
              { lesson!== ''?  <CardLine svg={<CardLessonSvg />}  title={lesson} />:null }
              { name!== ''?  <CardLine svg={<CardUserSvg />} userLink={userLink} title={name} />:null }
              { group !== ''?  <CardLine svg={<CardGroupSvg />} title={group} />:null  }
              { time!== ''?  <CardLine svg={<CardTimeSvg />} title={time} />:null }
             
              { date2!== ''?  <CardLine svg={<CardDateSvg />} title={date2} />:null  }
              { grade !== ''? <CardLine svg={<CardPointSvg />} title={grade} />:null }
              { grade2 !== ''?  <CardLine svg={<CardPointSvg />} title={grade2} />:null }
              { copy !== ''?  <CardLine svg={<CardCopySvg />} title={copy} />:null  }
              { cheating !== ''?  <CardLine svg={<CardCheatingSvg />} title={cheating} cheatingColor={cheatingColor} />:null }
              { close !== ''?  <CardLineBtn onLineClick={onLineClick} svg={<CardCloseSvg />} title={close} />:null }
              { date!== ''?  <CardLine svg={<CardDateSvg />} title={date} />:null }
              { user!== ''?  <CardLine svg={<CardUserSvg />} userLink={userLink} title={user} />:null }
            
              {
                moreOption.map((x=>{
                  return(
                    <CardLine svg={<CardLessonModeSvg />} title={x?.label} />
                  )
                }))
              }
              { cheating2 !== ''? 
              <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
                <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px]  inline-flex items-center justify-center`}>
                  <CardCheatingSvg />
                </div>
                <span className={`text-[14px]  font-semibold ${cheatingColor2}`}> {cheating2}</span>
              </li>:null
            }
             { authCode?  <CardLine svg={<CardKeySvg />} title={authCode} onGenerate={()=>onGenerate()} generate={true} />:null }
            {
                timer!== ''?
                <li className='flex items-center gap-[10px]'>
                  <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-[3px] inline-flex items-center justify-center`}>
                    <CardTimeSvg />
                  </div>
                  {
                    timer !== "00:00:00"?
                    <span className='text-[14px] txt-dark-gray font-semibold'>
                      <Countdown date={new Date(timer).getTime() + (minute)*60000} renderer={this.renderer.bind(this)}
                          onComplete={this.stopTimer.bind(this)}>
                      </Countdown>
                    </span>:
                     <span className='text-[14px] txt-dark-gray font-semibold'>{timer}</span>
                  }
                 
                  
                </li>:null
              }
            {
                timer2!== ''?
                <li className='flex items-center gap-[10px]'>
                  <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-[3px] inline-flex items-center justify-center`}>
                    <CardTimeSvg />
                  </div>
                  {
                    timer2 !== "00:00:00"?
                    <span className='text-[14px] txt-dark-gray font-semibold'>
                      <Countdown date={new Date(timer2).getTime() + (minute)*60000} renderer={this.renderer.bind(this)}
                          onComplete={this.stopTimer.bind(this)}>
                      </Countdown>
                    </span>:
                     <span className='text-[14px] txt-dark-gray font-semibold'>{timer2Text}</span>
                  }
                 
                  
                </li>:null
              }
               
            </ul>
            <div className='flex '>
            {
              adminLogBtn?
              <div className='pl-[20px] mt-[5px]'>
                <button onClick={()=>adminLogOnclick()} className='bg-gray-300 p-[1px_3px] text-[12px] rounded-[4px]'>Show Log</button>
              </div>:null
            }
            {
              adminZeroBtn?
              <div className='pl-[20px] mt-[5px]'>
                <button onClick={()=>adminZeroClick()} className='bg-gray-300 p-[1px_3px] text-[12px] rounded-[4px]'>Zero</button>
              </div>:null
            }
            </div>
           
            {gEng?  <GenEngCont essay={essay} speaking={speaking} gExam={gExam} gHw={gHw} /> :null }
           {
            sat?
            <SatCont satExam={satExam} satHw={satHw} satVerbHw={satVerbHw} satVerbExam={satVerbExam} /> :null
           }
           {!gEng && !sat&& showEmptyCard? <div className='mt-[20px] bg-gray-100 pt-[4px] pb-[4px] rounded-[6px] h-[70px] flex items-center justify-center'><span className='text-[14px] txt-blue'>{lang?.no_stats_available}</span></div>:null}
           <div className='flex items-center justify-between '>
           <div className='mt-[20px]'>
           { cheatingExam !== ''? 
            <div className='inline-flex items-center gap-[5px]'>
                <span className='txt-light-black text-[12px] font-bold underline underline-offset-1'>{lang.cheating}:</span>
                <span className='txt-light-black text-[12px] font-bold'>{cheatingExam}</span>
              </div> :null }
              {
                reCheckBtn?
                <button className='bg-blue text-[10px] h-[21px] pl-[5px] pr-[5px] text-white pb-[3px] pt-[3px] rounded-[3px]  mb-[-2px] block' onClick={()=>onReCheck()}>Recheck</button>:null
              }
           </div>
           {
              answerBtn?
              <div className='mt-[20px] flex justify-center'>
                <button disabled={disabled} onClick={()=>onClickAnswer()} className={`bg-blue text-white text-[10px] font-semibold pl-[5px] pr-[5px] pt-[3px] pb-[3px] rounded-[3px]  w-[65px] h-[21px]`}>{"Answer"}</button>
              </div>:null
            }
             {
              btn?
              <div className='mt-[20px] flex justify-end'>
                <button disabled={disabled} onClick={()=>onCLickBtn()} className={`${status == 0?greenStatus?'bg-blue':'bg-red':'bg-blue'} text-white text-[10px] font-semibold pl-[5px] pr-[5px] pt-[3px] pb-[3px] rounded-[3px] w-[65px] h-[21px]`}>{lang?.view}</button>
              </div>:null
            }
            {
              btn2?
              <div className='mt-[20px] flex justify-center'>
                <button disabled={disabled} onClick={()=>onCLickBtn()} className={`${btn2Color} text-white text-[10px] font-semibold pl-[5px] pr-[5px] pt-[3px] pb-[3px] rounded-[3px]  w-[65px] h-[21px]`}>{btn2Text}</button>
              </div>:null
            }
           
            {
              url && url !== ""?
              <div className='mt-[20px] flex justify-center'>
              <Link target={blank?"_blank":""} to={url} className={`${status ===0?'bg-red':'bg-blue'} text-center text-white text-[10px] pt-[2px] inline-block font-semibold pl-[5px] pr-[5px] pt-[3px] pb-[3px] rounded-[3px]  w-[65px] h-[21px]`}>{lang?.view}</Link>
            </div>:null
            }
           </div>
           {adminDelete? <button onClick={()=>onDeleteBtn()} className='bg-red text-[10px] h-[21px] pl-[5px] pr-[5px] text-white pb-[3px] pt-[3px] rounded-[3px] mt-[-22px] mb-[-2px] block'>Delete</button>:null}
          
      </div>
    )
  }
}
const mapStateToProps = (state)=>({lang:state.Data.lang})
export default connect(mapStateToProps)(Card1)
