import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { getReminders } from '../../actions/StaffAction';
import moment from 'moment';
import Card1 from '../../components/cards/Card1';
import ChangeStatus from '../../components/cards/ChangeStatus';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
import { insertNewData } from '../../actions/AdminActions';
import Search from '../../components/Search';
import LoadMoreBtn from '../../components/LoadMoreBtn';

export class RamininPage extends Component {
    state={
        status:1,
        search:"",
        page:1
    }
    componentDidMount(){
        this.props.getReminders(1,"", 1,"all")
    }
    addEditData=(data)=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                first_name:data?.first_name? data?.first_name:'',
                last_name:data?.last_name? data?.last_name:'',
                phone_number:data?.phone_number? data?.phone_number:'',
                email2:data?.email? data?.email:'',
                date:data?.date? data?.date:moment(new Date()).format("YYYY-MM-DD"),
                note:data?.note? data?.note:'',
                afterCallFunction:this.props.getReminders,
                afterCallParams:[this.state.page, this.state.search, this.state.status,"all"],
                requireds:["first_name", "last_name", "phone_number", "date"],
                url: data?.id? `remaining-info/${data?.id}`:'remaining-info',
                modalTitle: data?.id? `Edit Reminder`:'Add Reminder',
            }
        });
    }

    onDeleteBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`remaining-info/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getReminders("all")
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
          
    }

    onSelect=(id, data)=>{
        confirmAlert("warning", "Are you sure?", "Do you want to change status", "Yes, change it")
        .then(alertResp=>{
            if(alertResp === "success"){
                let formData = new FormData();
                formData.append("status", data?.id);
                formData.append("_method", "put")

                this.props.insertNewData(`remaining-info/${id}`, formData)
                .then(resp=>{
                    if(resp == "success"){
                        this.props.getReminders("all")
                        mixinAlert("success", "Status changed successfully")
                    }else{
                        mixinAlert("error", "Something went wrong")
                    }
                })
            }
        })
    }

    onChangeStatus=(data)=>{
        this.setState({status: data?.id})
        this.props.getReminders(this.state.page,this.state.search, data?.id,"all")
    }

    onSearchData=(search) =>{
        this.setState({search});
        this.props.getReminders(this.state.page,search, this.state.status,"all")
    }

    loadMore=(page)=>{
        this.setState({page});
        this.props.getReminders(page,this.state.search, this.state.status,"all")
    }
  render() {
    const {reminderData} = this.props;
    const {status} = this.state;
    // console.log(reminderData)
    const statusOptions = [
        {
            title: "Gözləyir",
            id:1
        },
        {
            title: "Yazıldı",
            id:2
        },
        {
            title: "Narahat etməyin",
            id:3
        },
        {
            title: "Uyğun deyil",
            id:4
        },
    ]
    return (
        <div className='w-full max-sm:pl-[10px] max-sm:pr-[10px] pl-[27px] pr-[27px]'>
            <Header title={"Reminder"} />
            <div className='flex justify-center  max-[1100px]:mt-[120px] max-[800px]:mt-[180px] '>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            <div className='mt-[42px] flex justify-between'>
                <HeaderButtons selected={status-1} onClick={this.onChangeStatus.bind(this)} buttons={statusOptions} />
                <HeaderButtons onClick={this.addEditData.bind(this, '')} buttons={[{title: "Add a data", id:0}]} />
            </div>

            <div className='mt-[30px] grid-full-250 items-start'>
            {
              reminderData?.map((data, i)=>{
                return (
                    <div className={`relative rounded-[10px] border border-[2px] ${data?.status === 1? "border-orange-300": data?.status === 2? "border-green-500": data?.status === 3?"border-red-500" : data?.status ===4?"border-red-600":""}`} key={i}>
                        <ChangeStatus onSelect={this.onSelect.bind(this, data?.id)} options={statusOptions} />
                        <Card1
                            statusBisible={false}
                            status={1}
                            btn={false}
                            title={data?.first_name+" "+data?.last_name}
                            email={data?.email}
                            phone={data?.phone_number}
                            date={moment(data?.date)?.format("DD/MM/YYYY")}
                            exam={data?.level}
                            btn2={true}
                            btn2Text={"Edit"}
                            btn2Color={"bg-blue"}
                            course={data?.status === 1? "Gözləyir": data?.status === 2? "Yazıldı": data?.status === 3?"Narahat etməyin" : data?.status ===4?"Uyğun deyil":""}
                            adminDelete={true}
                            onCLickBtn={this.addEditData.bind(this, data)}
                            onDeleteBtn={this.onDeleteBtn.bind(this, data?.id)}
                        />
                    </div>
                )
              })
            }
          </div>
          {
                reminderData?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={reminderData?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    reminderData: state.Data.reminderData
});
const mapDispatchToProps = {changeStateValue, getReminders, deleteData, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(RamininPage)
