import React, { Component } from 'react'
import Audio from '../exams/Audio'
import PlaySvg from '../svg/PlaySvg';

export class Card3 extends Component {
  state={
    audioVisible:false
  }
  render() {
    const {title, file_url, video_url} = this.props;
    const {audioVisible} = this.state;
    // console.log(file_url)
    return (
      <div className={`bg-white rounded-[30px] p-[7px] ${video_url && audioVisible?"flex-col items-start":"items-center"} flex  gap-[10px]`}>
        <span className='h-[36px] pl-[7px] pr-[7px] min-w-[36px]  inline-flex justify-center items-center txt-blue font-medium text-[16px] bg-light-blue rounded-full'>{title}</span>
        {
          audioVisible?
          file_url?
          <audio preload="auto|metadata|none" className='h-[36px] listening-audio' src={file_url} controls /> :
          video_url?
          <video className='w-full' src={video_url} controls  />:null
          
          :
          <div className='flex items-center gap-[10px]'>
            <button onClick={()=>this.setState({audioVisible:true})}>
              <PlaySvg />
            </button>
            <span className='text-[14px]'>Tap to play {file_url?"Audio":"Video"}</span>
          </div>
        }
        
      </div>
    )
  }
}

export default Card3
