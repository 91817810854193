import React, { Component } from 'react'
import ChangeSvg from '../svg/ChangeSvg'

export class ChangeStatus extends Component {
    state={
        visible: false
    }
  render() {
    const {options, onSelect=()=>{}} = this.props;
    const {visible} = this.state
    return (
      <div className='absolute right-[10px] top-[15px] flex flex-col justify-end items-end'>
            <button onClick={()=>this.setState({visible:!visible})}>
                <ChangeSvg />
            </button>
            {
                visible?
                <div className='w-[150px] h-auto p-[7px] bg-white rounded-[6px] shadow flex flex-col gap-[7px] z-10 items-start mt-[10px]'>
                    {
                        options?.map((data, i) => {
                            return (
                                <button onClick={()=>{onSelect(data); this.setState({visible: false})}} className='border-b w-full block text-start text-[13px] font-[600] border-dashed' key={i}>
                                    {data?.title}
                                </button>
                            )
                        })
                    }
                </div>:null
            }
           
      </div>
    )
  }
}

export default ChangeStatus
