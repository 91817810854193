import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import Input from './components/Input';
import DropSearch from '../DropSearch';
import { getCourses, getLessons } from '../../actions/MainAction';
import { getDropValue } from '../../actions/getRole';
import TextEditor from '../TextEditor';
import TextArea from '../TextArea';
import AdminModalFooter from './components/AdminModalFooter';
import FileInput from '../FileInput';
import { getLessonModes } from '../../actions/StaffAction';
import MultiSelect from '../MultiSelect';
import InputPhone from '../InputPhone';
import { getAdminLessons } from '../../actions/AdminActions';
export class AdminModal extends Component {
    componentDidMount(){
        this.props.getCourses();
        this.props.getLessonModes()
        this.props.getAdminLessons()
    }
    closeModal=()=>{
        this.props.changeStateValue({name:"adminModalData", value:{position:false}})
    }
    selectCourse=(data)=>{
        this.props.changeStateValue({name:"adminModalData.course_id", value:data})
        this.props.getLessons(data)
    }

    getStudyMode(){
        return [
            {
                value: 'One to One',
                label:'One to One'
            },
            {
                value: 'Group Two',
                label: 'Group Two'
            },
            {
                value: 'Group Three',
                label: 'Group Three'
            }
        ];
    }
   
  render() {
    const {adminModalData, studentCourses, lessons, lessonModes, adminLessons} = this.props;
    let lessonModeOption = [];
    for(const data of lessonModes){
        if(data?.id !=23 && data?.id != 24 && data.courses.length !==0){
            lessonModeOption.push({
                label: data.title,
                value: data.id
            })
        }
        
    }
    const roles = [
        {label: "Chief auditor", value: "chief_auditor"},
        {label: "Manager", value: "company_head"},
        {label: "Auditor", value: "auditor"},
        {label: "Content manager", value: "content_manager"},
        {label: "Office manager", value: "office_manager"},
        {label: "Teacher manager", value: "teacher_manager"},
        {label: "Teacher ", value: "teacher"},
        {label: "Head Teacher ", value: "head_teacher"},
        {label: "Accountant", value: "accountant"},
        {label: "Student", value: "student"},
        { label: "Content master", value: "content_master"},
    ]

    let lessonOption = [];
    for(const data of adminLessons){
        lessonOption.push({
            label: `${data?.course?.title} - ${data?.title}`,
            value: data?.id
        })
    }
    let studyMode =[
        {
            value: 'One to One',
            label:'One to One'
        },
        {
            value: 'Group Two',
            label: 'Group Two'
        },
        {
            value: 'Group Three',
            label: 'Group Three'
        },
       
        
      ];
      let classTime = [ 
      {
          label: "1 hour",
          value: 60
      },
      {
          label: "1.5 hours",
          value: 90
      },
      {
          label: "2 hours",
          value: 120
      },
      {
          label: "2.5 hours",
          value: 150
      },
      {
          label: "3 hours",
          value: 180
      },
      {
          label: "4 hours",
          value: 240
      }];
    // console.log(adminLessons)
    return (
      <div className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
        <div className='bg-white w-[700px]  shadow bg-white rounded-[10px] '>
            <Header closeBtn={this.closeModal.bind(this)} title={adminModalData?.modalTitle} />
            <div className={`p-[10px] bg-white rounded-b-[10px] max-h-[70vh] ${adminModalData?.overflow_def?'':"overflow-auto"} `}>
                <div  className='grid grid-cols-2 gap-[20px]'>
                    {adminModalData?.name || adminModalData?.name ===""? <Input  required name="adminModalData.name"  value={adminModalData?.name}  title={"Name"} />:null}
                    {adminModalData?.country || adminModalData?.country ===""? <Input  required name="adminModalData.country"  value={adminModalData?.country}  title={"Country"} />:null}
                    {adminModalData?.city || adminModalData?.city ===""? <Input  required name="adminModalData.city"  value={adminModalData?.city}  title={"City"} />:null}
                 
                   


                    {adminModalData?.first_name || adminModalData?.first_name ===""? <Input  required name="adminModalData.first_name"  value={adminModalData?.first_name}  title={"First Name"} />:null}
                    {adminModalData?.last_name || adminModalData?.last_name ===""? <Input  required name="adminModalData.last_name"  value={adminModalData?.last_name}  title={"Last Name"} />:null}
                    {adminModalData?.email || adminModalData?.email ===""? <Input  required name="adminModalData.email" type="email"  value={adminModalData?.email}  title={"Email"} />:null}
                    {adminModalData?.date_of_birth || adminModalData?.date_of_birth ===""? <Input  name="adminModalData.date_of_birth"  type="date" value={adminModalData?.date_of_birth}  title={"Date Of Birth"} />:null}
                    {adminModalData?.phone_number || adminModalData?.phone_number ===""? <InputPhone required  name="adminModalData.phone_number"  type="tel" value={adminModalData?.phone_number}  title={"Phone Number"} />:null}
                    {adminModalData?.password || adminModalData?.password ===""? <Input  name="adminModalData.password"  type="password" value={adminModalData?.password}  title={"Password"} />:null}
                    {adminModalData?.date || adminModalData?.date ===""? <Input required name="adminModalData.date"  type="date" value={adminModalData?.date}  title={"Date"} />:null}
                   
                    {adminModalData?.email2 || adminModalData?.email2 ===""? <Input  name="adminModalData.email2" type="email"  value={adminModalData?.email2}  title={"Email (optional)"} />:null}
                   
                    {adminModalData?.managerOpt ? <DropSearch name="adminModalData.user_id" required value={adminModalData.user_id} placeholder="Select a manager" options={adminModalData.managerOpt} title="Select a manager" /> :null }
                   
                    {adminModalData?.companyOption ? <DropSearch name="adminModalData.company_id" required value={adminModalData.company_id} placeholder="Select a Company" options={adminModalData.companyOption} title="Select a Company" /> :null }
                    {adminModalData?.roleOption ? <DropSearch name="adminModalData.role" required value={adminModalData.role} placeholder="Select a Role" options={adminModalData.roleOption} title="Select a Role" /> :null }



                    {adminModalData?.title || adminModalData?.title ===""? <Input  required name="adminModalData.title"  value={adminModalData?.title}  title={"Title"} />:null}
                    {adminModalData?.time || adminModalData?.time ===""? <Input  required name="adminModalData.time" type="number"  value={adminModalData?.time}  title={"Time"} />:null}
                    {adminModalData?.course_id || adminModalData?.course_id ===""? <DropSearch selectData={this.selectCourse.bind(this)} required value={adminModalData.course_id} placeholder="Select a Course" options={getDropValue(studentCourses, "title","id")} title="Select a Course" /> :null }
                    {adminModalData?.lesson_id || adminModalData?.lesson_id ===""? <DropSearch name="adminModalData.lesson_id" required value={adminModalData.lesson_id} placeholder="Select a Lesson" options={getDropValue(lessons, "title","id")} title="Select a Lesson" /> :null }
                    {adminModalData?.category || adminModalData?.category ==="" || adminModalData?.category == 0? <DropSearch name="adminModalData.category" required value={adminModalData.category} placeholder="Select a Category" options={[{label:"Standart", value:"0"}, {label:"Required", value:"1"}, {label:"End of course", value:"2"}]} title="Select a Category" /> :null }
                    
                    {adminModalData?.essay_type || adminModalData?.essay_type ===""? <DropSearch name="adminModalData.essay_type" required value={adminModalData.essay_type} placeholder="Select a Type" options={adminModalData?.typeOptions} title="Select a Type" /> :null }
                    {adminModalData?.type || adminModalData?.type ===""? <DropSearch name="adminModalData.type" required value={adminModalData.type} placeholder="Select a Type" options={adminModalData?.typeOptions} title="Select a Type" /> :null }
                    {adminModalData?.speaking_type || adminModalData?.speaking_type ===""? <DropSearch name="adminModalData.speaking_type" required value={adminModalData.speaking_type} placeholder="Select a Type" options={adminModalData?.typeOptions} title="Select a Type" /> :null }
                    {adminModalData?.essay_image || adminModalData?.essay_image ==="" ||  adminModalData?.essay_image ===null? <FileInput type="image" title='Upload image' valueName="adminModalData.image_full_url" value={adminModalData?.image_full_url} name="adminModalData.essay_image" />:null}
                    {adminModalData?.image_url || adminModalData?.image_url ==="" ||  adminModalData?.image_url ===null? <FileInput type="image" title='Upload image' valueName="adminModalData.image_full_url" value={adminModalData?.image_full_url} name="adminModalData.image_url" />:null}
                   
                    
                    
                    {adminModalData?.video_file || adminModalData?.video_file ==="" ||  adminModalData?.video_file ===null? <FileInput type="video" required title='Upload video' valueName="adminModalData.video_full_url" value={adminModalData?.video_full_url} name="adminModalData.video_file" />:null}
                    {adminModalData?.audio_file || adminModalData?.audio_file ==="" ||  adminModalData?.audio_file ===null? <FileInput type="audio" required title='Upload audio' valueName="adminModalData.audio_full_url" value={adminModalData?.audio_full_url} name="adminModalData.audio_file" />:null}
                    {adminModalData?.attachment || adminModalData?.attachment ==="" ||  adminModalData?.attachment ===null? <FileInput type="" required title='Upload File' valueName="adminModalData.image_full_url" value={adminModalData?.image_full_url} name="adminModalData.attachment" />:null}
                    {adminModalData?.lesson_mode_id || adminModalData?.lesson_mode_id ===""? <DropSearch name="adminModalData.lesson_mode_id"  value={adminModalData.lesson_mode_id} placeholder="Select a Lesson Mode" options={lessonModeOption} title="Select a Lesson Mode" /> :null }
                    {adminModalData?.status || adminModalData?.status ===""? <DropSearch name="adminModalData.status"  value={adminModalData.status} placeholder="Select a Status" options={[{label:'Active', value:'0'}, {label:'Inactive', value:'1'}]} title="Select a Lesson Mode" /> :null }
                    
                    {adminModalData?.assign_role || adminModalData?.assign_role == [] ? <MultiSelect selected={adminModalData.assign_role} placeholder={"Select a role"} options={roles} name="adminModalData.assign_role" title={"Select a role"} />:null}
                    {adminModalData?.lesson_mode? <MultiSelect selected={adminModalData.lesson_mode} placeholder={"Select a Lesson Mode"} options={getDropValue(lessonModes, "title","id")} name="adminModalData.lesson_mode" title={"Select a Lesson Mode"} />:null}
                    {adminModalData?.courses? <MultiSelect selected={adminModalData.courses} placeholder={"Select a Course"} options={getDropValue(studentCourses, "title","id")} name="adminModalData.courses" title={"Select a Courses"} />:null}
                    
                    
                    
                    {adminModalData?.level || adminModalData?.level ===""? <Input  required name="adminModalData.level"  value={adminModalData?.level}  title={"Level"} />:null}
                    {adminModalData?.grade || adminModalData?.grade ===""? <Input  required name="adminModalData.grade"  type="number" value={adminModalData?.grade}  title={"Grade"} />:null}
                   
                    {adminModalData?.total_hours || adminModalData?.total_hours ===""? <Input  required name="adminModalData.total_hours"  type="number" value={adminModalData?.total_hours}  title={"Total Hours"} />:null}
                    {adminModalData?.total_lesson || adminModalData?.total_lesson ===""? <Input required name="adminModalData.total_lesson"  type="number" value={adminModalData?.total_lesson}  title={"Total Lesson"} />:null}
                    {adminModalData?.total_month || adminModalData?.total_month ===""? <Input required name="adminModalData.total_month"  type="number" value={adminModalData?.total_month}  title={"Total Month"} />:null}
                    {adminModalData?.optional_times || adminModalData?.optional_times ===""? <Input  name="adminModalData.optional_times"  type="number" value={adminModalData?.optional_times}  title={"Optional times"} />:null}
                    {adminModalData?.lesson_time || adminModalData?.lesson_time ===""? <DropSearch name="adminModalData.lesson_time" required value={adminModalData.lesson_time} placeholder="Select a Lesson time" options={classTime} title="Select a Lesson time" /> :null }
                    {adminModalData?.group_type || adminModalData?.group_type ===""? <DropSearch name="adminModalData.group_type" required value={adminModalData.group_type} placeholder="Select a Group Type" options={studyMode} title="Select a Group Type" /> :null }

                    {adminModalData?.price || adminModalData?.price ===""? <Input  required name="adminModalData.price"  type="number" value={adminModalData?.price}  title={"Price"} />:null}
                    {adminModalData?.senior_price || adminModalData?.senior_price ===""? <Input  required name="adminModalData.senior_price"  type="number" value={adminModalData?.senior_price}  title={"Senior Price"} />:null}
                    {adminModalData?.row || adminModalData?.row ===""? <Input  required name="adminModalData.row"  type="number" value={adminModalData?.row}  title={"Row"} />:null}
                    
                    {adminModalData?.payment_mode || adminModalData?.payment_mode ===""? <DropSearch name="adminModalData.payment_mode" required value={adminModalData.payment_mode} placeholder="Select a Pay Mode" options={adminModalData?.typeOptions} title="Select a Pay Mode" /> :null }
                    {adminModalData?.duration || adminModalData?.duration ===""? <Input  required name="adminModalData.duration"  type="number" value={adminModalData?.duration}  title={"Duration"} />:null}
                    {adminModalData?.exam || adminModalData?.exam ===""? <Input  required name="adminModalData.exam"  type="number" value={adminModalData?.exam}  title={"Exam"} />:null}
                    {adminModalData?.essay || adminModalData?.essay ===""? <Input  required name="adminModalData.essay"  type="number" value={adminModalData?.essay}  title={"Essay"} />:null}
                    {adminModalData?.speaking || adminModalData?.speaking ===""? <Input  required name="adminModalData.speaking"  type="number" value={adminModalData?.speaking}  title={"Speaking"} />:null}
                    {adminModalData?.attendance || adminModalData?.attendance ===""? <Input  required name="adminModalData.attendance"  type="number" value={adminModalData?.attendance}  title={"Attendance"} />:null}
                    {adminModalData?.homework || adminModalData?.homework ===""? <Input  required name="adminModalData.homework"  type="number" value={adminModalData?.homework}  title={"Homework"} />:null}
                    {adminModalData?.speaking_practice || adminModalData?.speaking_practice ===""? <Input  required name="adminModalData.speaking_practice"  type="number" value={adminModalData?.speaking_practice}  title={"Speaking Practice"} />:null}
                    {adminModalData?.assessments || adminModalData?.assessments ===""? <Input  required name="adminModalData.assessments"  type="number" value={adminModalData?.assessments}  title={"Assessments"} />:null}
               
                    {adminModalData?.word_count || adminModalData?.word_count ===""? <Input  required name="adminModalData.word_count" type="number"  value={adminModalData?.word_count}  title={"Word count"} />:null}
                    


                    {adminModalData?.end_of_course_id || adminModalData?.end_of_course_id ===""? <DropSearch name="adminModalData.end_of_course_id" required value={adminModalData.end_of_course_id} placeholder="Select a end of course" options={lessonOption} title="Select a end of course" /> :null }
                    {adminModalData?.required_id? <MultiSelect selected={adminModalData.required_id} placeholder={"Select a required"} options={lessonOption} name="adminModalData.required_id" title={"Select a required"} />:null}
                    {adminModalData?.study_mode || adminModalData?.study_mode ===""? <DropSearch name="adminModalData.study_mode" required value={adminModalData.study_mode} placeholder="Select a study mode" options={this.getStudyMode()} title="Select a study mode" /> :null }
                    {adminModalData?.fee || adminModalData?.fee ===""? <Input  required name="adminModalData.fee" type="number"  value={adminModalData?.fee}  title={"Fee"} />:null}
                </div>
                <div className='mt-[20px]'>
                    {adminModalData?.question || adminModalData?.question ===""?<TextEditor name={"adminModalData.question"} required value={adminModalData?.question} title={"Question"} />:null}
                </div>
               
                <div className='mt-[20px]'>
                    {adminModalData?.prompt || adminModalData?.prompt ===""?<TextArea name={"adminModalData.prompt"}  value={adminModalData?.prompt} title={"Review Prompt"} />:null}
                </div>
                <div className='mt-[20px]'>
                    {adminModalData?.description || adminModalData?.description ===""?<TextArea name={"adminModalData.description"}  value={adminModalData?.description} title={"Note"} />:null}
                </div>
                <div className='mt-[20px]'>
                    {adminModalData?.second_prompt || adminModalData?.second_prompt ===""?<TextArea name={"adminModalData.second_prompt"}  value={adminModalData?.second_prompt} title={"First Prompt"} />:null}
                </div>
                <div className='mt-[20px]'>
                    {adminModalData?.first_prompt || adminModalData?.first_prompt ===""?<TextArea name={"adminModalData.first_prompt"}  value={adminModalData?.first_prompt} title={"First Promt"} />:null}
                </div>
                <div className='mt-[20px]'>
                    {adminModalData?.next_prompt || adminModalData?.next_prompt ===""?<TextArea name={"adminModalData.next_prompt"}  value={adminModalData?.next_prompt} title={"Next Promt"} />:null}
                </div>
                <div className='mt-[20px]'>
                    {adminModalData?.grade_prompt || adminModalData?.grade_prompt ===""?<TextArea name={"adminModalData.grade_prompt"}  value={adminModalData?.grade_prompt} title={"Grade Prompt"} />:null}
                </div>
                <div className='mt-[20px]'>
                    {adminModalData?.note || adminModalData?.note ===""?<TextArea name={"adminModalData.note"}  value={adminModalData?.note} title={"Note"} />:null}
                </div>
                <AdminModalFooter closeBtn={this.closeModal.bind(this)} data={adminModalData} />
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminModalData: state.Data.adminModalData,
    studentCourses: state.Data.studentCourses,
    lessons: state.Data.lessons,
    lessonModes: state.Data.lessonModes,
    adminLessons: state.Data.adminLessons,
});
const mapDispatchToProps = {changeStateValue, getCourses, getLessons, getLessonModes, getAdminLessons}
export default connect(mapStateToProps, mapDispatchToProps)(AdminModal)
