import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';

export class Card9 extends Component {

    getTime(time){
        let classTime = [ 
            {
                label: "1 hour",
                value: 60
            },
            {
                label: "1.5 hours",
                value: 90
            },
            {
                label: "2 hours",
                value: 120
            },
            {
                label: "2.5 hours",
                value: 150
            },
            {
                label: "3 hours",
                value: 180
            },
            {
                label: "4 hours",
                value: 240
            }
        ];
        const find = classTime.find(x=>x.value == +time);
        if(find){
            return find?.label
        }else{
            return ""
        }
    }

    onClickPrice=(data)=>{
        if(this.props.studentCalculatorModal?.group_type === "exists"){
            return
        }
        this.props.changeStateValue({name:"studentCalculatorModal.price_id",value: data?.id });
        this.props.changeStateValue({name:"studentCalculatorModal.study_mode",value: data?.group_type });
        this.props.changeStateValue({name:"studentCalculatorModal.lesson_hour",value: data?.lesson_time });
        if(!(this.props.studentCalculatorModal?.remaining_id && this.props.studentCalculatorModal?.remaining_id != "" && this.props.studentCalculatorModal?.selected_count !=="")){
            this.props.changeStateValue({name:"studentCalculatorModal.count",value: 12 });
            this.props.changeStateValue({name:"studentCalculatorModal.selected_count",value: 12 });
        }
        
        this.props.changeStateValue({name:"studentCalculatorModal.week_day",value: 1 });
        this.props.changeStateValue({name:"studentCalculatorModal.fee",value: data?.price });
    }
  render() {
    const {data, price_id} = this.props;
   
    return (
      <div className='bg-white p-[10px] shadow rounded-[10px]'>
        <h3 className='txt-blue font-[500]'>{data?.title} / {data?.price} AZN</h3>

        <p className='text-[14px]'>Duration: <span className='font-[600]'>{data?.total_hours} hours / {data?.total_lessos} lessons / {data?.total_month} month</span></p>
        <p className='text-[14px]'>Each lesson: <span className='font-[600]'>{this.getTime(data?.lesson_time)}</span></p>
        <div className='mt-[10px]'>
            <button onClick={this.onClickPrice.bind(this,data)} className={`${price_id === data?.id? "bg-blue text-white":"bg-white border border-[var(--blue)] txt-blue"} font-[600] text-[14px] p-[4px_12px] rounded-[6px]`}>{price_id === data?.id?"Selected":"Select"}</button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    studentCalculatorModal: state.Data.studentCalculatorModal
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Card9)
