import React, { Component } from 'react'
import img from "../../images/profileBgMini.png";
import defUser from "../../images/defaultUser.jpeg"
import { getRole } from '../../actions/getRole';
import { connect } from 'react-redux';
import CardLocationSvg from '../svg/CardLocationSvg';
import CardTelSvg from '../svg/CardTelSvg';
import CardCourseSvg from '../svg/CardCourseSvg';
import CardScheduleSvg from '../svg/CardScheduleSvg';
import moment from 'moment';
import CardPdfSvg from '../svg/CardPdfSvg';
import { changeStateValue } from '../../redux/MainReducer';

export class ExamSchedule extends Component {

  openPdfBtn=(data)=>{
    this.props.changeStateValue({
      name:"randevuPdfModal",
      value:{
          position:true,
          name:data?.user?.full_name,
          center:data?.user?.company?.name,
          date:moment(data?.date).format("DD MMMM, YYYY"),
          time:data?.time
      }
    })
  }
  render() {
    const {examSchedule, lang} = this.props;
    return (
        <div className='bg-white rounded-[20px] p-[30px]'>
            <div className='flex flex-col relative'>
                <img className='w-full h-[108px]' src={img} />
                <div className='w-[87px] h-[87px] p-[3px] bg-white rounded-full flex justify-center items-center ml-auto mr-auto mt-[-40px]'>
                    <img className='w-full h-full rounded-full' src={examSchedule?.user?.avatar_full_url==''? defUser: examSchedule?.user?.avatar_full_url} />
                </div>
                <h3 className='text-center mt-[15px] txt-dark-blue text-[20px] font-bold'>{examSchedule?.user?.full_name}</h3>
                <span className='inline-block ml-auto mr-auto txt-dark-gray font-medium text-[14px]'>{getRole(examSchedule?.user?.role,lang)}</span>
            </div>
            <div className='mt-[20px] ml-[34px] mr-[34px] flex flex-col gap-[20px]  overflow-auto'>
          
            <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
              <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-full  inline-flex items-center justify-center`}>
                 <CardScheduleSvg />
              </div>
              <span  className='text-[14px]  font-semibold txt-dark-gray'>{moment(examSchedule?.date)?.format("MMMM DD")+", "+examSchedule?.time}</span>
            </li>
            <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
              <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-full  inline-flex items-center justify-center`}>
                 <CardCourseSvg />
              </div>
              <span  className='text-[14px]  font-semibold txt-dark-gray'>{examSchedule?.course?.title}</span>
            </li>
           
            <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
              <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-full  inline-flex items-center justify-center`}>
                 <CardLocationSvg />
              </div>
              <a href={"https://maps.app.goo.gl/scqEVjyFgTE4q5ej7"} target='_blank' className='text-[14px]  font-semibold txt-dark-gray'>CELT White City</a>
            </li>
              <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
              <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-full  inline-flex items-center justify-center`}>
                 <CardTelSvg />
              </div>
              <a href={`tel:+994504150402}`}  className='text-[14px]  font-semibold txt-dark-gray'>+99450 415 04 02</a>
            </li>
            <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
              <button onClick={this.openPdfBtn.bind(this,examSchedule)} className='flex items-center gap-[10px]'>
              <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-full  inline-flex items-center justify-center`}>
                 <CardPdfSvg />
              </div>
              <span  className='text-[14px]  font-semibold txt-dark-gray'>View ticket</span>
              </button>
            </li>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ExamSchedule)
