import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { getThirtyCheckins } from '../../actions/StaffAction';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HeaderButtons from '../../components/HeaderButtons';

export class ThirtyDayCheckinPage extends Component {
    state={
        checkinsData: [],
        attCount: "12+",
        selected: 0
    }
    componentDidMount(){
        this.props.getThirtyCheckins()
        .then(resp=>{
            this.setState({checkinsData: resp})
        })
    }

    getFilteredData=(data, filt)=>{
        if(filt === "12+"){
            return data?.filter(x=> x.attendace >= 12)
        }else{
            return data?.filter(x=> x.attendace < 12)
        }
    }

    getPercentage(data, type){
        let total = data?.length;
        let info = this.getFilteredData(data, type)?.length;

        return Math.round((info*100)/total)
    }
  render() {
    const {checkinsData, attCount, selected} = this.state;
    // console.log(checkinsData)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="30 Days Check-in" />
            <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[170px] mt-[42px]'>
                <div className='mt-[28px]'>
                    <HeaderButtons fontSize="text-[15px]" pt={true} padding={true} onClick={(data)=>this.setState({attCount: data?.title, selected:data?.id})} buttons={[{title: `12+ ( ${ this.getFilteredData(checkinsData, "12+")?.length} ), ( ${this.getPercentage(checkinsData, "12+")}% )`, id:0}, {title: `12- ( ${ this.getFilteredData(checkinsData, "12-")?.length} ), ( ${this.getPercentage(checkinsData, "12-")}% )`, id:1}]} selected={selected} />
                </div>
                <div className='w-full bg-white p-[10px] shadow rounded-[10px] mt-[30px]'>
                    <ul className='flex w-full justify-between border rounded-[10px]'>
                        <li className='border-r w-1/4 p-[10px] font-[600]'>Student</li>
                        <li className='border-r w-1/4 p-[10px] font-[600]'>Teacher</li>
                        <li className='border-r w-1/4 p-[10px] font-[600]'>Lesson Mode</li>
                        {/* <li className='border-r w-1/4 p-[10px] font-[600]'>Course</li> */}
                        {/* <li className='border-r  w-1/4 p-[10px] font-[600]'>Study Mode</li> */}
                        <li className=' w-1/4 p-[10px] font-[600]'>Check-In Count</li>
                    </ul>
                    {
                        this.getFilteredData(checkinsData, attCount)?.map((data, i) => {
                            return (
                                <ul key={i} className='flex w-full justify-between border rounded-[10px] mt-[5px]'>
                                    <li className='border-r w-1/4 p-[10px] font-[600]'>
                                        <Link to={`/students/${data?.student?.id}`} target='_blank' className='text-blue-500'>{data?.student?.full_name}</Link></li>
                                    <li className='border-r w-1/4 p-[10px] font-[600]'>{data?.teacher?.full_name}</li>
                                    <li className='border-r w-1/4 p-[10px] font-[600]'>{data?.lesson_mode}</li>
                                    {/* <li className='border-r w-1/4 p-[10px] font-[600]'>{data?.course?.title}</li> */}
                                    {/* <li className='border-r  w-1/4 p-[10px] font-[600]'>{data?.study_mode}</li> */}
                                    <li className=' w-1/4 p-[10px] font-[600]'>{data?.attendace}</li>
                                </ul>
                            )
                        })
                    }
                    
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {getThirtyCheckins}
export default connect(mapStateToProps, mapDispatchToProps)(ThirtyDayCheckinPage)
