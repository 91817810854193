import React, { Component } from 'react'
import Header from './Header'
import { Link } from 'react-router-dom'

export class ReminderModal extends Component {

    closeBtn=()=>{
        this.props.onClose()
    }
  render() {
    const {reminderCount} = this.props;
    return (
      <div className='background_shadow flex justify-center items-center '> 
            <div className='bg-white w-[600px] shadow rounded-[10px] max-[640px]:ml-[20px] max-[640px]:mr-[20px]'>
                <Header closeBtn={this.closeBtn.bind(this)} title="Bu gün zəng ediləcəklər" />
               <div className='p-[10px]'>
                    <p className='text-center text-[20px] max-[600px]:text-[16px]'>
                        Bu gün {reminderCount} nəfərlə danışılmalıdır. Zəhmət olmasa səhifəyə keçid edib bir göz atın
                    </p>
                   <div className='mt-[20px] flex justify-center w-full '>
                        <Link onClick={this.closeBtn.bind(this)} to="/reminder" className='bg-blue p-[7px_10px] text-white text-[14px] font-[600] rounded-[6px]'>
                            Səhifəyə keçid edin
                        </Link>
                   </div>
               </div>
            </div>
      </div>
    )
  }
}

export default ReminderModal
