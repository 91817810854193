
import React, { useEffect, useState } from 'react'
import Dashboard from './pages/Dashboard'
import SideBar from './components/navbar/SideBar'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import ExamResults from './pages/student_role/ExamResults'
import Homework from './pages/student_role/Homework'
import Exams from './pages/student_role/Exams'
import CheckIn from './pages/student_role/CheckIn'
import StartHomework from './pages/student_role/StartHomework'
import Speakings from './pages/student_role/Speakings'
import Listenings from './pages/student_role/Listenings'
import LoginPage from './pages/LoginPage'
import { useDispatch, useSelector } from 'react-redux'
import { checkUser, getUnreadMessageCount } from './actions/MainAction'
import EssayReviews from './pages/student_role/EssayReviews'
import SpeakingReview from './pages/student_role/SpeakingReview'
import HwResults from './pages/student_role/HwResults'
import ListeningData from './pages/student_role/ListeningData'
import SpPracticeReviews from './pages/student_role/SpPracticeReviews'
import IALEXReview from './pages/student_role/IALEXReview'
import Certificate  from './pages/student_role/Certificate'
import Courses from './pages/student_role/Courses'
import Resources from './pages/student_role/Resources'
import Essays from './pages/student_role/Essays'
import SpPractice from './pages/student_role/SpPractice'
import IALExams from './pages/student_role/IALExams'
import PartScoreModal from './components/modals/PartScoreModal'
import Loader from './components/Loader'
import MainModal from './components/modals/MainModal'
import StartIALExams from './pages/student_role/StartIALExams'
import StartExam from './pages/student_role/StartExam'
import ToeflSpeakingStart from './pages/student_role/ToeflSpeakingStart'
import ToeflWritingStart from './pages/student_role/ToeflWritingStart'
import ParentCheckin from './pages/parent_role/ParentCheckin'
import CheckinByStudentTeacher from './pages/CheckinByStudentTeacher'
import EssayUngraded from './pages/company_head_role/EssayUngraded'
import SpeakingUngraded from './pages/company_head_role/SpeakingUngraded'
import CeltEssaysReview from './pages/company_head_role/CeltEssaysReview'
import CeltSpeakingReview from './pages/company_head_role/CeltSpeakingReview'
import CeltExamResults from './pages/company_head_role/CeltExamResults'
import CeltHwResults from './pages/company_head_role/CeltHwResults'
import Teachers from './pages/company_head_role/Teachers'
import Parents from './pages/company_head_role/Parents'
import OfficeManagers from './pages/company_head_role/OfficeManagers'
import UserModal from './components/modals/UserModal'
import CeltStudents from './pages/company_head_role/CeltStudents'
import CeltStudentMainPage from './pages/company_head_role/student_info/CeltStudentMainPage'
import EditStudent from './pages/company_head_role/student_info/EditStudent'
import TeacherStats from './pages/company_head_role/TeacherStats'
import StudentStats from './pages/company_head_role/StudentStats'
import ManagerCheckins from './pages/company_head_role/ManagerCheckins'
import Reports from './pages/company_head_role/Reports'
import AssessmentUngraded from './pages/company_head_role/AssessmentUngraded'
import TeacherCounting from './pages/company_head_role/TeacherCounting'
import TeacherEnrols from './pages/company_head_role/teacher_info/TeacherEnrols'
import TeacherBonus from './pages/company_head_role/teacher_info/TeacherBonus'
import OfficeManagerBonus from './pages/company_head_role/OfficeManagerBonus'
import ToeflExams from './pages/company_head_role/ToeflExams'
import CeltBonuses from './pages/company_head_role/CeltBonuses'
import PaymentDue from './pages/company_head_role/PaymentDue'
import OfficeIalExams from './pages/company_head_role/OfficeIalExams'
import SpeakingCheckins from './pages/company_head_role/SpeakingCheckins'
import TeacherReviews from './pages/parent_role/TeacherReviews'
import TeacherStudents from './pages/teacher_role/TeacherStudents'
import TeacherAllEssays from './pages/teacher_role/TeacherAllEssays'
import TeacherSpeakings from './pages/teacher_role/TeacherSpeakings'
import TeacherCheckins from './pages/teacher_role/TeacherCheckins'
import TeacherOwnCounting from './pages/teacher_role/TeacherOwnCounting'
import TeacherExamResults from './pages/teacher_role/TeacherExamResults'
import TeacherHwResults from './pages/teacher_role/TeacherHwResults'
import GeneralStats from './pages/company_head_role/GeneralStats'
import OfficeApplicants from './pages/company_head_role/OfficeApplicants'
import MessagesPage from './pages/MessagesPage'
import HwResultReview from './pages/company_head_role/HwResultReview'
import CeltSpeakingPractice from './pages/company_head_role/CeltSpeakingPractice'
import SpeakingTeacherCheckins from './pages/teacher_role/SpeakingTeacherCheckins'
import SettingsPage from './pages/SettingsPage'
import AdminIalExams from './pages/admin/AdminIalExams'
import AdminModal from './components/modals/AdminModal'
import AdminSpPractice from './pages/admin/AdminSpPractice'
import AdminEssay from './pages/admin/AdminEssay'
import AdminSpeakings from './pages/admin/AdminSpeakings'
import AdminExams from './pages/admin/AdminExams'
import AdminAddEditExams from './pages/admin/exams/AdminAddEditExams'
import AdminExamPreview from './pages/admin/exams/AdminExamPreview'
import NotAllowdLogin from './components/NotAllowdLogin'
import ErrorPage from './components/ErrorPage'
import PublicMain from './public_pages/pages/PublicMain'
import Header from './public_pages/sections/Header'
import Footer from './public_pages/sections/Footer'
import PublicCenters from './public_pages/pages/PublicCenters'
import SingleCenterPage from './public_pages/pages/SingleCenterPage'
import PublicExamResults from './public_pages/pages/PublicExamResults'
import PublicAcceptedStudents from './public_pages/pages/PublicAcceptedStudents'
import RegisterPage from './pages/RegisterPage'
import ConfirmPage from './pages/ConfirmPage'
import { checkPublicUser } from './actions/PublicActions'
import ForgotPassword from './pages/ForgotPassword'
import PublicLevelCheck from './public_pages/pages/PublicLevelCheck'
import ListeningExam from './public_pages/pages/start_exams/ListeningExam'
import ReadingExam from './public_pages/pages/start_exams/ReadingExam'
import WritingExam from './public_pages/pages/start_exams/WritingExam'
import SpeakingExam from './public_pages/pages/start_exams/SpeakingExam'
import PublicPages from './pages/company_head_role/PublicPages'
import NotFoundPage from './pages/NotFoundPage'
import LoadingPage from './pages/LoadingPage'
import PublicProfile from './public_pages/pages/PublicProfile'
import TeacherGeneralStats from './pages/teacher_role/TeacherGeneralStats'
import AdminImages from './pages/admin/AdminImages'
import AdminExamExceptions from './pages/admin/AdminExamExceptions'
import AdminExamSettings from './pages/admin/AdminExamSettings'
import AdminCompanies from './pages/admin/AdminCompanies'
import AdminManagers from './pages/admin/AdminManagers'
import AdminGroups from './pages/admin/AdminGroups'
import AdminAllocations from './pages/admin/AdminAllocations'
import AdminCourses from './pages/admin/AdminCourses'
import AdminLessons from './pages/admin/AdminLessons'
import AdminListenings from './pages/admin/AdminListenings'
import AdminListeningData from './pages/admin/AdminListeningData'
import AdminLessonModes from './pages/admin/AdminLessonModes'
import AdminExamResults from './pages/admin/AdminExamResults'
import ExamResultsReview from './pages/admin/ExamResultsReview'
import AdminHwResults from './pages/admin/AdminHwResults'
import AdminStudents from './pages/admin/AdminStudents'
import AdminParents from './pages/admin/AdminParents'
import AdminPrompts from './pages/admin/AdminPrompts'
import AdminCourseRules from './pages/admin/AdminCourseRules'
import AdminUnlockStudents from './pages/admin/AdminUnlockStudents'
import SpeakingTeacherCheckinsView from './pages/teacher_role/SpeakingTeacherCheckinsView'
import AdminNotAssignEnrolls from './pages/admin/AdminNotAssignEnrolls'
import TeacherEvents from './pages/company_head_role/teacher_info/TeacherEvents'
import AdminTeachers from './pages/admin/AdminTeachers'
import AccountantTeachers from './pages/accountant_role/AccountantTeachers'
import AccountingCounting from './pages/accountant_role/AccountingCounting'
import AdminStaff from './pages/admin/AdminStaff'
import UnitsPage from './pages/student_role/UnitsPage'
import AdminCoachExceptions from './pages/admin/AdminCoachExceptions'
import AuditorCompanyData from './pages/auditor_role/AuditorCompanyData'
import AuditorCompanies from './pages/auditor_role/AuditorCompanies'
import AuditorStudents from './pages/auditor_role/AuditorStudents'
import EmailVerifyPage from './pages/EmailVerifyPage'
import AuditTeachers from './pages/auditor_role/AuditTeachers'
import AuditWeeklyCoach from './pages/auditor_role/AuditWeeklyCoach'
import AudotMonthlCoach from './pages/auditor_role/AudotMonthlCoach'
import UnsubCribeEmail from './pages/UnsubCribeEmail'
import TeacherCheck from './pages/company_head_role/TeacherCheck'
import AdminTypeOfStudents from './pages/admin/AdminTypeOfStudents'
import AdminEssayReviews from './pages/admin/AdminEssayReviews'
import AdminSpeakingReviews from './pages/admin/AdminSpeakingReviews'
import ManagerCheckinTeacher from './pages/company_head_role/ManagerCheckinTeacher'
import ProgressLoading from './components/ProgressLoading'
import AdminResources from './pages/admin/AdminResources'
import AccountantContracts from './pages/accountant_role/AccountantContracts'
import TeacherownCheck from './pages/teacher_role/TeacherownCheck'
import EnrollPaymentsPage from './pages/company_head_role/EnrollPaymentsPage'
import AccountantPayments from './pages/accountant_role/AccountantPayments'
import AccountantCompanyData from './pages/accountant_role/AccountantCompanyData'
import AdminCompanyPays from './pages/admin/AdminCompanyPays'
import AdminGeneralStats from './pages/admin/AdminGeneralStats'
import AccountantGOPages from './pages/accountant_role/AccountantGOPages'
import SAbroadPage from './pages/company_head_role/SAbroadPage'
import PublicExamSingle from './public_pages/pages/PublicExamSingle'
import SupportMessage from './pages/SupportMessage'
import SupportMain from './pages/support_role/SupportMain'
import CompanyExamException from './pages/company_head_role/CompanyExamException'
import CompanyCoachException from './pages/company_head_role/CompanyCoachException'
import SupportSchedule from './pages/support_role/SupportSchedule'
import OficeExamSchedule from './pages/company_head_role/OficeExamSchedule'
import LoginLogs from './pages/admin/LoginLogs'
import SupportExamKeys from './pages/support_role/SupportExamKeys'
import AdminExamPoolQuestions from './pages/admin/AdminExamPoolQuestions'
import ExamPoolAddEdit from './pages/admin/exam_pool/ExamPoolAddEdit'
import ExamCategories from './pages/admin/ExamCategories'
import ExamSubCategories from './pages/admin/exam_categories/ExamSubCategories'
import ExamQuestionCreatePage from './pages/admin/exam_categories/ExamQuestionCreatePage'
import RandevuPdf from './components/RandevuPdf'
import AdminEndOfException from './pages/admin/AdminEndOfException'
import AdminCourseRequiredPage from './pages/admin/AdminCourseRequiredPage'
import EndOfStudentsStats from './pages/admin/EndOfStudentsStats'
import ExamSectionsPage from './pages/admin/exam_categories/ExamSectionsPage'
import AdminPriceCalculator from './pages/admin/AdminPriceCalculator'
import PriceCalculator from './pages/company_head_role/PriceCalculator'
import CompanyOnlineApplies from './pages/company_head_role/CompanyOnlineApplies'
import AdminPoolingExams from './pages/admin/AdminPoolingExams'
import NewRequestModal from './components/modals/NewRequestModal'
import StartPoolingExam from './pages/student_role/pooling_exam/StartPoolingExam'
import SeniorModal from './components/modals/SeniorModal'
import AdminSeniorRequests from './pages/admin/AdminSeniorRequests'
// import io from 'socket.io-client';
import { changeStateValue } from './redux/MainReducer'
import ExamPermissionModal from './components/modals/ExamPermissionModal'
import socket from './actions/socketIo'
import AdminFailedStudentException from './pages/admin/AdminFailedStudentException'
import LoginBlocks from './pages/support_role/LoginBlocks'
import SpeakingPracticeLevelCheck from './pages/student_role/SpeakingPracticeLevelCheck'
import AdminManagerCompaniesPage from './pages/admin/AdminManagerCompaniesPage'
import LinkGroups from './pages/teacher_role/LinkGroups'
import AssessmentCoachPage from './pages/company_head_role/AssessmentCoachPage'
import ReadAssessmentsPage from './pages/ReadAssessmentsPage'
import AdminReportCoachPage from './pages/admin/AdminReportCoachPage'
import AdminAllExceptionCoach from './pages/admin/AdminAllExceptionCoach'
import Register2FA from './pages/Register2FA'
import Verify2FA from './pages/Verify2FA'
import AdminExamPromptsPage from './pages/admin/AdminExamPromptsPage'
import StartMockExam from './pages/student_role/pooling_exam/StartMockExam'
import MocExamDetails from './pages/student_role/pooling_exam/MocExamDetails'
import Pusher from 'pusher-js';
import { notAlert } from './actions/SweetAlerts'
import StudentTReviews from './pages/student_role/StudentTReviews'
import StudentPoolingAnswer from './components/modals/StudentPoolingAnswer'
import FairModal from './components/FairModal'
import AdminPriceSchemePage from './pages/admin/AdminPriceSchemePage'
import RamininPage from './pages/company_head_role/RamininPage'
import ThirtyDayCheckinPage from './pages/company_head_role/ThirtyDayCheckinPage'
import ManagerCompStudents from './pages/company_head_role/ManagerCompStudents'
// import { SOCKET_API } from './MAIN_API'

function App() {
  const navigate = useNavigate();
  const user = useSelector(state=> state.Data.user);
  const partScoreModal = useSelector(state=> state.Data.partScoreModal);
  const loader = useSelector(state=> state.Data.loader);
  const loaderLong = useSelector(state=> state.Data.loaderLong);
  const mainModal = useSelector(state=> state.Data.mainModal);
  const sideBarVisible = useSelector(state=> state.Data.sideBarVisible);
  const adminModalData = useSelector(state=> state.Data.adminModalData);
  const userModalData = useSelector(state=> state.Data.userModalData);
  const notAllowData = useSelector(state=> state.Data.notAllowData);
  const errorPageData = useSelector(state=> state.Data.errorPageData);
  const publicUser = useSelector(state=> state.Data.publicUser);
  const progressLoading = useSelector(state=> state.Data.progressLoading);
  const randevuPdfModal = useSelector(state=> state.Data.randevuPdfModal);
  const newApplyCheck = useSelector(state=> state.Data.newApplyCheck);
  const seniorModalData = useSelector(state=> state.Data.seniorModalData);
  const examPermissionModalData = useSelector(state=> state.Data.examPermissionModalData);
  const studentPoolingAnswersModal = useSelector(state=> state.Data.studentPoolingAnswersModal);
  const fairModalPosition = useSelector(state=> state.Data.fairModalPosition);
  const [loadPage, setLoadPage] = useState(true)
  // const socket = io(SOCKET_API, {
  //   transports: ['websocket', 'polling']
  // });
  const dispatch = useDispatch();
  useEffect(()=>{
      // console.log()
      // console.log("ssss")


    document.addEventListener("wheel", (event) => {  
      if (document.activeElement.type === "number") {  
          document.activeElement.blur(); 
      }  
    }); 
    if(!localStorage.getItem("userToken")){
      // navigate("/login")
      setTimeout(()=>{
        setLoadPage(false)
      }, 1000)
    }else{
      dispatch(checkUser())
      .then(resp=>{
       
        if(resp == "error"){
          localStorage.removeItem("userToken");
          // navigate("/login")
          navigate("/")
          setTimeout(()=>{
            setLoadPage(false)
          }, 1000)
        }else if(resp === "2FA"){
          navigate("/2fa-register")
        }else{
          // dispatch(getUnreadMessageCount())
          setTimeout(()=>{
            setLoadPage(false)
          }, 1000)
        }
      })
    }

    if(localStorage.getItem("vipPublicToken")){
      dispatch(checkPublicUser())
      .then(resp=>{
        if(resp === "success"){
          setTimeout(()=>{
            setLoadPage(false)
          }, 1000)
        }else{
          setTimeout(()=>{
            setLoadPage(false)
          }, 1000)
        }
        
      })
    }else{
      setTimeout(()=>{
        setLoadPage(false)
      }, 1000)
    }
  }, []);

  useEffect(()=>{
    if(user?.role == "support" ){
      socket.on("support_request", (msg)=>{
        const data = JSON.parse(msg);
        // console.log(data)
        if(user?.role == "support"){
          dispatch(changeStateValue({
            name:"examPermissionModalData",
            value:{
              position:true,
              ...data 
            } 
          }))
        }
        // if(data?.unit_type == "unit" || data?.unit_type == "mid"){
        //   if(data?.company_id == user?.company_id){
        //     if(user?.role == "company_head" ||  user?.role == "office_manager"){
        //       dispatch(changeStateValue({
        //         name:"examPermissionModalData",
        //         value:{
        //           position:true,
        //           ...data 
        //         } 
        //       }))
        //     }
        //   }
        // }else{
        //   if(user?.role == "support"){
        //     dispatch(changeStateValue({
        //       name:"examPermissionModalData",
        //       value:{
        //         position:true,
        //         ...data 
        //       } 
        //     }))
        //   }
        // }
      })
    }
   
    var pusher = new Pusher('ed46bbb38b7a098f016f', {
      cluster: 'mt1'
    });

    var channel = pusher.subscribe('message-chanel');
    channel.bind('message-notification', (data) =>{
      new Notification('Hello World')
      const checkUSer = data?.id.find(userId=> userId ==user?.id);
      if(checkUSer){
        notAlert("", "New Message")
      }
    });
  }, [user])

  
  // console.log(user)
  return (
    <div className={user!==""?'flex':''}>
      {
        loadPage?
        <LoadingPage />:null
      }
      <Routes>
        <Route path='/veirfy-email-address' element={<EmailVerifyPage />} />
        <Route path='/unsub/:email' element={<UnsubCribeEmail />} />
        
      
      </Routes>
     {/* PUBLIC PAGES */}
      {!loadPage && user === ""? <Header />:null  }
      {
       !loadPage && user === ""?
        <main className='bg-[#fff]'>
          <Routes>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/confirm-code' element={<ConfirmPage />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/' element={<PublicMain />} />
            <Route path='/centers' element={<PublicCenters />} />
            <Route path='/centers/:id' element={<SingleCenterPage />} />
            {/* <Route path='/exam-results' element={<PublicExamResults />} /> */}
            <Route path='/exam-results/:id' element={<PublicExamSingle />} />
            <Route path='/accepted-students' element={<PublicAcceptedStudents />} />
            {
              publicUser !==""?
              <>
                <Route path='/level-check' element={<PublicLevelCheck />} />
                <Route path='/listening-exam' element={<ListeningExam />} />
                <Route path='/reading-exam' element={<ReadingExam />} />
                <Route path='/writing-exam' element={<WritingExam />} />
                <Route path='/speaking-exam' element={<SpeakingExam />} />
                <Route path='/profile' element={<PublicProfile />} />
              </>:null
            }
            <Route path='/2fa-register' element={<Register2FA />} />
            <Route path='/2fa-verify' element={<Verify2FA />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </main>:null
      }
     
      {/* ---------------------------- */}

      {/* VIP PAGES */}
      {!loadPage &&  user!= '' && sideBarVisible? <SideBar />:null  }
      {
       !loadPage && user !==""?
         <main className='w-full h-screen overflow-auto pb-[50px] max-[450px]:h-[98vh]'>
        <Routes>
          <Route path='/read-assessments' element={<ReadAssessmentsPage />} />
          <Route path='/2fa-register' element={<Register2FA />} />
          <Route path='/2fa-verify' element={<Verify2FA />} />
          {
            user?.role === "support"?
            <>
             
              <Route path='/' element={<SupportSchedule />} />
              <Route path='/admin-essay-reviews' element={<AdminEssayReviews />} />
              <Route path='/admin-speaking-reviews' element={<AdminSpeakingReviews />} />
              <Route path='/exam-schedule' element={<SupportSchedule />} />
              <Route path='/students/:id' element={<CeltStudentMainPage />} />
              <Route path='/students/:id/:exam_id' element={<CeltStudentMainPage />} />
              <Route path='/exam-keys' element={<SupportExamKeys />} />
              <Route path='/login-blocks' element={<LoginBlocks />} />
              <Route path='/support-message' element={<SupportMain />} />
            </>:
             <Route path='/' element={<Dashboard />} />
          }
          {
            user?.role === "student"?
            <>
              <Route path='/homework' element={<Homework />} />
              <Route path='/exams' element={<Exams />} />
              <Route path='/exam-start/:id' element={<StartExam />} />
              <Route path='/exam-start/:id/toefl-speaking' element={<ToeflSpeakingStart />} />
              <Route path='/exam-start/:id/toefl-writing' element={<ToeflWritingStart />} />
              <Route path='/check-in' element={<CheckIn />} />
              <Route path='/hw-start/:id' element={<StartHomework />} />
              <Route path='/speaking' element={<Speakings />} />
              <Route path='/speaking/:courseId' element={<Speakings />} />
            
            
              <Route path='/essay-reviews' element={<EssayReviews />} />
              <Route path='/speaking-reviews' element={<SpeakingReview />} />
             
              <Route path='/speaking-practice-reviews' element={<SpPracticeReviews />} />
              <Route path='/ial-ex-reviews' element={<IALEXReview />} />

              <Route path='/essays' element={<Essays />} />
              <Route path='/essays/:courseId' element={<Essays />} />
              <Route path='/essays/:courseId/:lessonId' element={<Essays />} />

              <Route path='/speaking-practice/:courseId' element={<SpPractice />} />
              <Route path='/speaking-practice/:courseId/:lessonId' element={<SpPractice />} />
              <Route path='/speaking-practice' element={<SpPractice />} />
              <Route path='/ial-exams' element={<IALExams />} />
              <Route path='/ial-exams/:id' element={<StartIALExams />} />
              <Route path='/exam-results' element={<ExamResults />} />
              <Route path='/homework-results' element={<HwResults />} />
              <Route path='/units/:id' element={<UnitsPage />} />

              <Route path='/start-pooling-exams/:id' element={<StartPoolingExam />} />
              <Route path='/start-mock-exams/:id' element={<StartMockExam />} />
              <Route path='/moc-details/:id/:examId' element={<MocExamDetails />} />
              <Route path='/sp-practice-level-check/:courseId' element={<SpeakingPracticeLevelCheck />} />
              <Route path='/sp-practice-level-check/:courseId/:lessonId' element={<SpeakingPracticeLevelCheck />} />
              <Route path='/sp-practice-level-check' element={<SpeakingPracticeLevelCheck />} />
             {
              user?.send_email_status ==1?
              <Route path='/teacher-reviews' element={<StudentTReviews />} />:null
             }
            </>:null
          }
          {
             user?.role=== "parent"?
             <>
              <Route path='/exam-results' element={<ExamResults />} />
              <Route path='/homework-results' element={<HwResults />} />
              <Route path='/parent-check-in' element={<ParentCheckin />} />
              <Route path='/teacher-reviews' element={<TeacherReviews />} />
              <Route path='/units/:id' element={<UnitsPage />} />
             </>:null
          }
             <Route path='/check-in/:student_id/:teacher_id' element={<CheckinByStudentTeacher />} />
              <Route path='/certificates' element={<Certificate />} />
              <Route path='/courses' element={<Courses />} />
              <Route path='/resources' element={<Resources />} />
              <Route path='/listenings' element={<Listenings />} />
              <Route path='/listenings/:id' element={<ListeningData />} />
              <Route path='/messages' element={<MessagesPage />} />
              <Route path='/profile' element={<SettingsPage />} />
          {
             user?.role === "company_head" ||  
             user?.role === "office_manager" ||
             user?.role === "teacher" ||
             user?.role === "head_teacher" ||
             user?.role === "speaking_teacher" ||
             user?.role === "super_admin" ||
             user?.role === "accountant" ||
             user?.role === "auditor" ||
             user?.role === "content_manager"?
             <>
              <Route path='/company-exam-exception' element={<CompanyExamException />} />
              <Route path='/company-coach-exception' element={<CompanyCoachException />} />

              <Route path='/abroad-statistics' element={<SAbroadPage />} />
              <Route path='/essay-ungraded' element={<EssayUngraded />} />
              <Route path='/speaking-ungraded' element={<SpeakingUngraded />} />
              <Route path='/celt-essays' element={<CeltEssaysReview />} />
              <Route path='/celt-speakings' element={<CeltSpeakingReview />} />
              <Route path='/celt-exam-results' element={<CeltExamResults />} />
              <Route path='/celt-homwork-results' element={<CeltHwResults />} />
              <Route path='/teachers' element={<Teachers />} />
              <Route path='/parents' element={<Parents />} />
              <Route path='/office-managers' element={<OfficeManagers />} />
              <Route path='/students' element={<CeltStudents />} />
              <Route path='/students/:id' element={<CeltStudentMainPage />} />
              <Route path='/students/:id/edit' element={<EditStudent type="edit" />} />
              <Route path='/students/create' element={<EditStudent type="add" />} />
              <Route path='/teacher-stats' element={<TeacherStats />} />
              <Route path='/student-stats' element={<StudentStats />} />
              <Route path='/teacher/:id/checkins' element={<ManagerCheckins />} />
              <Route path='/manager-checkins' element={<ManagerCheckinTeacher />} />
              <Route path='/reports' element={<Reports />} />
              <Route path='/reports-ungraded' element={<AssessmentUngraded />} />
              <Route path='/office-counting' element={<TeacherCounting />} />
              <Route path='/teacher/:id/enroll' element={<TeacherEnrols />} />
              <Route path='/teachers/:id/bonus' element={<TeacherBonus />} />
              <Route path='/office-managers/:id/bonus' element={<OfficeManagerBonus />} />
              <Route path='/toefl-exams' element={<ToeflExams />} />
              <Route path='/bonuses' element={<CeltBonuses />} />
              {/* <Route path='/payment-due' element={<PaymentDue />} /> */}
              <Route path='/payment-due' element={<EnrollPaymentsPage />} />
              <Route path='/office-ial-exams' element={<OfficeIalExams />} />
              <Route path='/speaking-checkin' element={<SpeakingCheckins />} />
              <Route path='/general-stats' element={<GeneralStats />} />
              <Route path='/applicants' element={<OfficeApplicants />} />
              <Route path='/hw-review/:id' element={<HwResultReview />} />
              <Route path='/celt-speakings-practice' element={<CeltSpeakingPractice />} />
              <Route path='/public-pages' element={<PublicPages />} />
              <Route path='/teacher-students' element={<TeacherStudents />} />
              <Route path='/teacher-all-essays' element={<TeacherAllEssays />} />
              <Route path='/teacher-speakings' element={<TeacherSpeakings />} />
              <Route path='/teacher-checkins' element={<TeacherownCheck />} />
              <Route path='/teacher-counting' element={<TeacherOwnCounting />} />
              <Route path='/teacher-exam-results' element={<TeacherExamResults />} />
              <Route path='/teacher-hw-results' element={<TeacherHwResults />} />
              <Route path='/speaking-teacher-checkins' element={<SpeakingTeacherCheckins />} />
              <Route path='/speaking-teacher-checkins-view' element={<SpeakingTeacherCheckinsView />} />
              <Route path='/teacher-gen-stats' element={<TeacherGeneralStats />} />
              <Route path='/teachers/:id/events' element={<TeacherEvents />} />
              <Route path='/units/:id' element={<UnitsPage />} />
              <Route path='/teacher-check' element={<TeacherCheck />} />
              <Route path='/support-message' element={<SupportMessage />} />
              <Route path='/ofice-schedule' element={<OficeExamSchedule />} />
              <Route path='/exam-schedule' element={<SupportSchedule />} />
              <Route path='/price-calculator' element={<PriceCalculator />} />
              <Route path='/online-applies' element={<CompanyOnlineApplies />} />
              <Route path='/login-blocks' element={<LoginBlocks />} />

              <Route path='/link-groups' element={<LinkGroups />} />
              <Route path='/exam-keys' element={<SupportExamKeys />} />
              <Route path='/assessment-coach' element={<AssessmentCoachPage />} />
              <Route path='/assessment-coach/:id' element={<AssessmentCoachPage />} />
              <Route path='/reminder' element={<RamininPage />} />
              <Route path='/thirty-checkin' element={<ThirtyDayCheckinPage />} />
              {
                user?.role == "company_head"?
                <>
                <Route path='/admin-exam-results/:id/reviews' element={<ExamResultsReview />} />
                <Route path='/audit-company/:id/:company_name' element={<AuditorCompanyData />} />
                <Route path='/manager-company-students/:id/:company_name' element={<ManagerCompStudents />} />
                </>:null
              }
              
           
              {
                 user?.role=="super_admin" || user?.role == "content_manager"?
                 <>
                  <Route path='/admin-ial-exams' element={<AdminIalExams />} />
                  <Route path='/admin-sp-practice' element={<AdminSpPractice />} />
                  <Route path='/admin-essays' element={<AdminEssay />} />
                  <Route path='/admin-speaking' element={<AdminSpeakings />} />
                  <Route path='/admin-exams' element={<AdminExams pageType={"main"} />} />
                  <Route path='/admin-exams/create' element={<AdminAddEditExams />} />
                  <Route path='/admin-exams/:id' element={<AdminAddEditExams />} />
                  <Route path='/admin-exams/:id/prev' element={<AdminExamPreview />} />
                  <Route path='/exam-pooling-questions' element={<AdminExamPoolQuestions />} />
                  <Route path='/exam-pooling-questions/:id' element={<ExamPoolAddEdit />} />
                  <Route path='/exam-categories' element={<ExamCategories />} />
                  <Route path='/exam-categories/:id' element={<ExamSubCategories />} />
                  <Route path='/exam-categories/:id/:sub_cat_id' element={<ExamSectionsPage />} />
                  <Route path='/exam-categories/:id/:sub_cat_id/:section_id' element={<ExamQuestionCreatePage />} />
                  <Route path='/admin/pooling-exams' element={<AdminPoolingExams />} />
                 </>:null
              }
              {
                user?.role == "accountant" ||  user?.role == "super_admin"?
                <>
                
                <Route path='/accountant-teachers/:id/:company_name' element={<AccountantTeachers />} />
                <Route path='/accountant-teachers-contracts/:id/:company_name' element={<AccountantContracts />} />
                <Route path='/accountant-payments/:id/:company_name' element={<AccountantPayments />} />
                <Route path='/accountant-teachers-counting/:id' element={<AccountingCounting />} />
                <Route path='/accountant-company-data/:id/:company_name' element={<AccountantCompanyData />} />
                <Route path='/accountant-celtgo/:id/:company_name' element={<AccountantGOPages />} />
                </>:null
              }
              {
                user?.role == "auditor"?
                <>
                <Route path='/audit-company/:id/:company_name' element={<AuditorCompanyData />} />
                <Route path='/admin-students' element={<AuditorCompanies path={'audit-students'} />} />
                <Route path='/audit-teachers' element={<AuditorCompanies path={'audit-teachers'} />} />
                <Route path='/audit-students/:id/:company_name' element={<AuditorStudents />} />
                <Route path='/audit-teachers/:id/:company_name' element={<AuditTeachers />} />
                <Route path='/audit-weekly-coach' element={<AuditWeeklyCoach />} />
                <Route path='/audit-monthly-coach' element={<AudotMonthlCoach />} />
                </>:null
              }
              
             {
              user?.role=="super_admin"?
              <>
                <Route path='/admin-images' element={<AdminImages />} />
                <Route path='/admin-exam-exception' element={<AdminExamExceptions />} />
                <Route path='/admin-exam-settings' element={<AdminExamSettings />} />
                <Route path='/admin-companies' element={<AdminCompanies />} />
                <Route path='/admin-managers' element={<AdminManagers />} />
                <Route path='/admin-groups' element={<AdminGroups />} />
                <Route path='/admin-allocations' element={<AdminAllocations />} />
                <Route path='/admin-courses' element={<AdminCourses />} />
                <Route path='/admin-lessons' element={<AdminLessons />} />
                <Route path='/admin-listenings' element={<AdminListenings />} />
                <Route path='/admin-listenings/:id' element={<AdminListeningData />} />
                <Route path='/admin-lesson-modes' element={<AdminLessonModes />} />
                <Route path='/admin-exam-results' element={<AdminExamResults />} />
                <Route path='/admin-hw-results' element={<AdminHwResults />} />
                <Route path='/admin-students' element={<AdminStudents />} />
                <Route path='/admin-parents' element={<AdminParents />} />
                <Route path='/admin-course-rules' element={<AdminCourseRules />} />
                <Route path='/admin-unlock-students' element={<AdminUnlockStudents />} />
                <Route path='/admin-prompts' element={<AdminPrompts />} />
                <Route path='/admin-not-assign' element={<AdminNotAssignEnrolls />} />
                <Route path='/admin-exam-results/:id/reviews' element={<ExamResultsReview  />} />
                <Route path='/teacher/:id/enroll' element={<TeacherEnrols />} />
                <Route path='/admin-teachers' element={<AdminTeachers />} />
                <Route path='/admin-staff' element={<AdminStaff />} />
                <Route path='/admin-coach-exception' element={<AdminCoachExceptions />} />
                <Route path='/admin-student-types' element={<AdminTypeOfStudents />} />
                <Route path='/admin-essay-reviews' element={<AdminEssayReviews />} />
                <Route path='/admin-speaking-reviews' element={<AdminSpeakingReviews />} />
                <Route path='/admin-resources' element={<AdminResources />} />
                <Route path='/admin-company-pays' element={<AdminCompanyPays />} />
                <Route path='/admin-general-stats' element={<AdminGeneralStats />} />
                <Route path='/admin-login-logs' element={<LoginLogs />} />
                <Route path='/audit-weekly-coach' element={<AuditWeeklyCoach />} />
                <Route path='/audit-monthly-coach' element={<AudotMonthlCoach />} />
                <Route path='/audit-company/:id/:company_name' element={<AuditorCompanyData />} />
                <Route path='/admin/price-calculator' element={<AdminPriceCalculator />} />
                <Route path='/admin/price-scheme' element={<AdminPriceSchemePage />} />
                <Route path='/manager-companies' element={<AdminManagerCompaniesPage />} />


                <Route path='/exam-pooling-questions' element={<AdminExamPoolQuestions />} />
                <Route path='/exam-pooling-questions/:id' element={<ExamPoolAddEdit />} />
                <Route path='/exam-categories' element={<ExamCategories />} />
                {/* <Route path='/exam-categories/:id' element={<ExamSubCategories />} />
                <Route path='/exam-categories/:id/:sub_cat_id' element={<ExamQuestionCreatePage />} /> */}

                <Route path='/office-managers' element={<OfficeManagers />} />
                <Route path='/endof-exception' element={<AdminEndOfException />} />
                <Route path='/course-requireds' element={<AdminCourseRequiredPage />} />
                <Route path='/problem-students' element={<EndOfStudentsStats />} />
                <Route path='/students/:id/:exam_id' element={<CeltStudentMainPage />} />
                <Route path='/admin-senior-requests' element={<AdminSeniorRequests />} />
                <Route path='/failed-student-exception' element={<AdminFailedStudentException />} />
                <Route path='/admin-report-coach' element={<AdminReportCoachPage />} />
                <Route path='/admin-exception-coach' element={<AdminAllExceptionCoach />} />
                
                <Route path='/admin-exam-prompt' element={<AdminExamPromptsPage />} />
              </>:null
             }
             </>:null
          }
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
        
      </main>:null
      }

      {
        partScoreModal?.position?
        <PartScoreModal />:null
      }
      {
        mainModal?.position?
        <MainModal />:null
      }
      {studentPoolingAnswersModal?.position? <StudentPoolingAnswer />:null}
      
       {adminModalData?.position ? <AdminModal />:null}
      {userModalData?.position? <UserModal />:null}
      {notAllowData?.position? <NotAllowdLogin />:null}
      {errorPageData?.position? <ErrorPage />:null}
      {seniorModalData?.position? <SeniorModal />:null}
      {examPermissionModalData?.position? <ExamPermissionModal />:null}
      {
        loader || loaderLong?
        <Loader />:null
      }
      {progressLoading?.position?<ProgressLoading />:null }
     {randevuPdfModal?.position? <RandevuPdf />:null}
     {newApplyCheck !==0? <NewRequestModal />:null }
     {/* {fairModalPosition && (user?.role == "student" || user?.role == "parent" || user?.role == "teacher" || user?.role == "head_teacher")? <FairModal />:null} */}
  </div>
  )
}

export default App
