import React, { Component } from 'react'
import { getLessonModes, getOldestEnrollCount } from '../../../../actions/StaffAction';
import { getCourses } from '../../../../actions/MainAction';
import { connect } from 'react-redux';
import Header from '../../../../components/modals/Header';
import DropSearch from '../../../../components/DropSearch';
import DefaulData from '../../../../components/modals/components/DefaulData';
import { changeStateValue } from '../../../../redux/MainReducer';
import { getPriceScheme } from '../../../../actions/AdminActions';
import Card9 from '../../../../components/cards/Card9';
import { mixinAlert } from '../../../../actions/SweetAlerts';

export class NewEnrollParams extends Component {

    componentDidMount(){
        this.props.getLessonModes();
        this.props.getCourses();
        if(this.props.studentCalculatorModal.price_id && this.props.priceSchemeData?.length !== 0){
            const data = this.props.priceSchemeData?.find(x=> x.id == this.props.studentCalculatorModal.price_id )
         
            if(data){
              
                this.props.changeStateValue({name:"studentCalculatorModal.price_id",value: data?.id });
                this.props.changeStateValue({name:"studentCalculatorModal.study_mode",value: data?.group_type });
                this.props.changeStateValue({name:"studentCalculatorModal.lesson_hour",value: data?.lesson_time });
                this.props.changeStateValue({name:"studentCalculatorModal.count",value: 12 });
                this.props.changeStateValue({name:"studentCalculatorModal.selected_count",value: 12 });
                this.props.changeStateValue({name:"studentCalculatorModal.week_day",value: 1 });
                this.props.changeStateValue({name:"studentCalculatorModal.fee",value: data?.price });

                this.props.changeStateValue({name:"studentCalculatorModal.file",value: ""});
                this.props.changeStateValue({name:"studentCalculatorModal.file_full_url",value: ""});
                this.props.changeStateValue({name:"studentCalculatorModal.image",value: ""});
                this.props.changeStateValue({name:"studentCalculatorModal.image_full_url",value: ""});
            }
        }
    }


    onSelectLessonMode=(lesson_mode_id)=>{
        // if(this.props.studentCalculatorModal?.study_mode !==""){
        //   this.props.getEnrollData(lesson_mode_id,this.props.studentCalculatorModal?.study_mode, this.props.student?.id)
        // }
        this.props.getOldestEnrollCount(lesson_mode_id, this.props.student?.id)
        this.props.changeStateValue({name:"studentCalculatorModal.course_id", value:""})
        this.props.changeStateValue({name:"studentCalculatorModal.price_id",value: "" });
        this.props.changeStateValue({name:"studentCalculatorModal.study_mode",value: "" });
        this.props.changeStateValue({name:"studentCalculatorModal.lesson_hour",value: "" });
        this.props.changeStateValue({name:"studentCalculatorModal.count",value: "" });
        this.props.changeStateValue({name:"studentCalculatorModal.selected_count",value: "" });
        this.props.changeStateValue({name:"studentCalculatorModal.week_day",value: "" });
        this.props.changeStateValue({name:"studentCalculatorModal.fee",value: ""});
        this.props.changeStateValue({name:"priceSchemeData",value: []});

        this.props.changeStateValue({name:"studentCalculatorModal.file",value: ""});
        this.props.changeStateValue({name:"studentCalculatorModal.file_full_url",value: ""});
        this.props.changeStateValue({name:"studentCalculatorModal.image",value: ""});
        this.props.changeStateValue({name:"studentCalculatorModal.image_full_url",value: ""});
        this.props.changeStateValue({name:"studentCalculatorModal.remaining_id",value: ""});
        
    }

    onSelectCourse=(data)=>{
        this.props.getPriceScheme(data);
    }

    changePage=(page)=>{
        if(page === "pay_params"){
            if(
                this.props.studentCalculatorModal.lesson_mode_id == "" || 
                this.props.studentCalculatorModal.course_id == "" || 
                this.props.studentCalculatorModal.study_mode == "" || 
                this.props.studentCalculatorModal.lesson_hour == "" || 
                this.props.studentCalculatorModal.selected_count == "" || 
                this.props.studentCalculatorModal.fee == "" 
            ){
                if( this.props.studentCalculatorModal.price_id ==""){
                    mixinAlert("warning", "Please select one of them");
                    return
                   }
                    mixinAlert("warning", "Please, fill the all required fields");
                    return
            }
        }
        this.props.changeStateValue({
            name:"studentCalculatorModal.page",
            value:page
        })
    }

    onClickRemining=(data)=>{
        if(this.props.studentCalculatorModal.remaining_id === data?.id){
            this.props.changeStateValue({name:"studentCalculatorModal.file",value: ""});
            this.props.changeStateValue({name:"studentCalculatorModal.file_full_url",value: ""});
            this.props.changeStateValue({name:"studentCalculatorModal.image",value: ""});
            this.props.changeStateValue({name:"studentCalculatorModal.image_full_url",value: ""});
            this.props.changeStateValue({name:"studentCalculatorModal.remaining_id",value: ""});
            this.props.changeStateValue({name:"studentCalculatorModal.selected_count",value: 12});
            this.props.changeStateValue({name:"studentCalculatorModal.count",value: 12});
        }else{
            this.props.changeStateValue({name:"studentCalculatorModal.file",value: data?.file});
            this.props.changeStateValue({name:"studentCalculatorModal.file_full_url",value: data?.file_full_url});
            this.props.changeStateValue({name:"studentCalculatorModal.image",value: data?.image});
            this.props.changeStateValue({name:"studentCalculatorModal.image_full_url",value: data?.image_full_url});
            this.props.changeStateValue({name:"studentCalculatorModal.remaining_id",value: data?.id});
            this.props.changeStateValue({name:"studentCalculatorModal.selected_count",value: data?.count});
            this.props.changeStateValue({name:"studentCalculatorModal.count",value: data?.count});
        }
       
    }
  render() {
    const {studentCalculatorModal, lessonModes, priceSchemeData, oldestEnrollCount} = this.props;
    // console.log(oldestEnrollCount)
    let lessonModeOption = [];
    for(const data of lessonModes){
        if(data?.id !=23 && data?.id != 24 && data.courses.length !==0){
            lessonModeOption.push({
                label: data.title,
                value: data.id
            })
        }
        
    }
    let coursesOption = [];
    const checkCourse = lessonModes.find(item=>item?.id == studentCalculatorModal?.lesson_mode_id);
    // console.log(lessonModes)
    if(checkCourse){
        for(const data of checkCourse?.courses){
            if(data.id!==36 && data?.id!==30 &&  data?.id!==35){
                coursesOption.push({
                    label: data?.title,
                    value: data?.id
                })
            }
        }
    }

    return (
        <div className='bg-white w-[700px] shadow rounded-[10px] max-[730px]:ml-[20px] max-[730px]:mr-[20px]'>
            <Header title="Enrollment information" closeBtn={()=>this.props.onClose()} />
            <div className={`p-[20px] max-h-[70vh] ${ priceSchemeData?.length !==0 ? " overflow-auto":""}`}>
                <div className='grid grid-cols-2 max-[600px]:grid-cols-1 gap-[20px] mt-[20px]'>
                {
                    studentCalculatorModal?.group_type==="exists"?
                    <DefaulData title="Lesson mode" value={studentCalculatorModal?.lesson_mode} />:
                    <DropSearch selectData={this.onSelectLessonMode.bind(this)} required value={studentCalculatorModal.lesson_mode_id} placeholder="Lesson mode" name="studentCalculatorModal.lesson_mode_id"  options={lessonModeOption}  title="Lesson mode" /> 
                }
                {
                    studentCalculatorModal?.group_type==="exists"?
                    <DefaulData title="Course" value={coursesOption?.find(x=>x?.value ==studentCalculatorModal.course_id )?.label} />:
                    <DropSearch selectData={this.onSelectCourse.bind(this)} required value={studentCalculatorModal.course_id} placeholder="Course" name="studentCalculatorModal.course_id"  options={coursesOption}  title="Course" /> 
                }
                </div>
                {
                    oldestEnrollCount?.id !=="" && studentCalculatorModal.course_id !==""?
                    <div className='mt-[15px] border-t pt-[15px]'>
                        <h3 className='text-center txt-blue font-[600] text-[18px]'>Remaining Lesson</h3>
                        <button onClick={this.onClickRemining.bind(this, oldestEnrollCount)} className={`text-[14px] inline-flex items-center gap-[10px] font-[600] p-[4px_10px] rounded-[6px] border border-[var(--blue)] txt-blue`}>
                           <input checked={studentCalculatorModal?.remaining_id == oldestEnrollCount?.id} type='checkbox' /> Remaining Count: {oldestEnrollCount?.count}
                        </button>
                    </div>:null
                }
                {
                    priceSchemeData?.length !==0?
                    <>
                     <h2 className='text-center mt-[20px] border-t pt-[15px] txt-blue font-[600] text-[18px]'>Select one of them</h2>
                    <div className='grid grid-cols-2 max-[600px]:grid-cols-1 gap-[20px] mt-[10px] bg-gray-100 rounded-[10px] p-[10px]'>
                        {
                            priceSchemeData?.map((data, i) => {
                                return (
                                    <Card9 
                                        key={i}
                                        data={data}
                                        price_id={studentCalculatorModal?.price_id}
                                    />
                                )
                            })
                        }
                    </div>
                    </>:null
                }
               <div className='flex mt-[20px] justify-between'>
                <button onClick={this.changePage.bind(this, "group")} className="h-[25px] bg-orange p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                    Back
                </button>
                <button onClick={this.changePage.bind(this, "pay_params")} className="h-[25px] bg-blue p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                    Save & next
                </button>
            </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentCalculatorModal: state.Data.studentCalculatorModal,
    lessonModes: state.Data.lessonModes,
    priceSchemeData: state.Data.priceSchemeData,
    oldestEnrollCount: state.Data.oldestEnrollCount,
});
const mapDispatchToProps = {getLessonModes, getCourses, changeStateValue, getPriceScheme, getOldestEnrollCount}
export default connect(mapStateToProps, mapDispatchToProps)(NewEnrollParams)
