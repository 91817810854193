import React, { Component } from 'react'
import { endRecording, pauseRecording, resumeRecording, startRecording } from '../../../../actions/ReorAudio'
import MicSvg from '../../../../components/svg/MicSvg';
import { connect } from 'react-redux';
import {changeStateValue} from "../../../../redux/MainReducer";
import { ReactMic } from 'react-mic';
import wave from "../../../../images/recordGif.gif"
 let interval;
export class Part1Questions extends Component {
   
    state={
        timeThink: "00:05",
        micPosition: 'start',
        base64:'',
        index:0,
        startTimer:5,
        startedConv: false,
        isRecording:false
    }

    timeStart(){
        this.setState({
            timeThink: "00:05",
            micPosition: 'start',
            startTimer:5
        })
        interval = setInterval(()=>{
            let timer = this.state.startTimer;
            timer--;
            this.setState({
                startTimer: timer,
                timeThink:`00:0${timer}`
            })
            if(timer === 0){
                if(this.state.index < this.props.data?.questions?.length-1){
                    clearInterval(interval);
                    this.startMic();
                }
                
            }
        }, 1000)
    }
    componentDidMount(){
       this.timeStart();
    }
    startMic=()=>{
        if(!this.state.startedConv){
            startRecording();
            this.setState({startedConv:true})
        }else{
            resumeRecording();
        }
        this.setState({isRecording: true})
        
        this.setState({micPosition:'pause'})
    }
    pauseMic=()=>{
        this.setState({isRecording: false})
        if(this.state.index < this.props.data?.questions?.length-1){
            pauseRecording();
            let index = this.state.index;
            this.setState({index: index+=1});
            clearInterval(interval);
            this.timeStart()
            this.setState({micPosition:'start'})
        }else{
            const result = endRecording();
            this.setState({base64: result?.audio_url});
            this.props.changeStateValue({name:`examsData.0.given_answer`, value:result.audioName})
        }
        
    }
    resetPart=()=>{
        this.setState({
            timeThink: "00:05",
            micPosition: 'start',
            base64:'',
            index:0,
            startTimer:5,
            startedConv: false
        });
        clearInterval(interval);
        this.props.changeStateValue({name:`examsData.0.given_answer`, value:''});
        this.props.onReset()
    }

    nextPage=()=>{
        this.setState({
            timeThink: "00:05",
            micPosition: 'start',
            base64:'',
            index:0,
            startTimer:5,
            startedConv: false
        });
        clearInterval(interval);
        
        this.props.onChangePage();
        this.setState({isRecording: false})
    }
  render() {
    const {data} = this.props;
    const {timeThink, micPosition, base64, index, isRecording} = this.state;
    // console.log(base64)
    return (
        <div className='w-full flex flex-col gap-[10px] justify-center items-center'>
            <h3 className='txt-blue text-[28px] font-[600]'>Part 1</h3>
            {
                base64 == ""?
                <>
                    <h2 className='text-[22px] font-[600] txt-orange'>Question {index+1}</h2>
                    <h2 className='text-[22px] font-[600]' dangerouslySetInnerHTML={{__html: data?.questions[index]?.description}}></h2>
                    {
                        micPosition === "pause"?
                        <div className='flex flex-col items-center justify-center mt-[20px]'>
                            <div className='bg-orange w-[50px] h-[50px] flex justify-center items-center rounded-full shadow '>
                                <MicSvg size={30} />
                            </div>
                            <span className='text-[12px]'>Recording...</span>
                        </div>
                        :null
                    }
                     {isRecording?  <img className='h-[30px] max-sm:w-[50%] w-[120px]' alt='recording' src={wave} />:null}
                     {/* {isRecording?
                        <ReactMic
                            record={isRecording}
                            className={`w-[150px] h-[60px] ${isRecording?"":'hidden'}`}
                            onStop={()=> console.log("stop")}
                            onData={()=> console.log("data")}
                            strokeColor="#000000"
                            backgroundColor="white"
                        />:null
                        
                    } */}
                    {
                        micPosition == "start"?
                        <button onClick={this.startMic.bind(this)} className='text-[14px] text-white bg-blue p-[5px_10px] rounded-[5px] mt-[20px]'>Time to think: {timeThink}</button>:null
                    }
                    {
                        micPosition == "pause"?
                        <button onClick={this.pauseMic.bind(this)} className='text-[14px] text-white bg-blue p-[5px_10px] rounded-[5px]'>Save & Next</button>:null
                    }
                </>:
                <>
                    <h2 className='text-[22px] font-[600] txt-orange'>It’s the end of Part 1</h2>
                    <p  className='text-center'>You can review your part 1 recording by clicking the Play button below </p>
                    <audio className='max-sm:w-[200px] w-[220px] h-[40px] max-[320px]:w-[150px]' controls src={base64} />

                    <p className='text-center text-[14px]'>You can click Next Part to continue Part 2
                        <br />
                        Or Reset this part to record again</p>
                    <div className='flex justify-center gap-[30px]'>
                        <button onClick={this.resetPart.bind(this)} className='text-white bg-orange p-[4px_10px] rounded-[5px] text-[14px]'>Reset This Part</button>
                        <button onClick={this.nextPage.bind(this)} className='text-white bg-blue p-[4px_10px] rounded-[5px] text-[14px]'>Next Part</button>
                    </div>
                </>
            }
            
            
        </div>
    )
  }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Part1Questions)
