import React, { Component } from 'react'

export class CardLessonSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="#1E398F"
          d="M4.707 12.707 8 16l3.293-3.293L8 9.414l-3.293 3.293ZM3.293 11.293 6.586 8 3.293 4.707 0 8l3.293 3.293ZM4.707 3.293 8 0l3.293 3.293L8 6.586 4.707 3.293ZM12.707 4.707 9.414 8l3.293 3.293L16 8l-3.293-3.293Z"
        />
      </svg>
    )
  }
}

export default CardLessonSvg
