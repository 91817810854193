import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import generatePDF, {Resolution, Margin} from 'react-to-pdf';
import { resetStateValue } from '../redux/MainReducer';
import { mixinAlert } from '../actions/SweetAlerts';
function RandevuPdf({closeModal}) {
    const targetRef = useRef();
    const randevuPdfModal = useSelector((state)=>state.Data.randevuPdfModal);
    const dispath = useDispatch()
    function downloadPdf(){
        generatePDF(targetRef,{filename:"Exam Confirmation Ticket.pdf"})
        .then(resp=>{
            dispath(resetStateValue({name:'randevuPdfModal'}))
            mixinAlert("success", "PDF Downloaded successfully.")
        })

    }
    function closeModal(e){
        if(e.target.className?.includes("background_shadow")){
            dispath(resetStateValue({name:'randevuPdfModal'}))

        }
    }
    // console.log(targetRef)
  return (
    <div onClick={e=>closeModal(e)} className='background_shadow flex justify-center items-center flex-col gap-[20px]'>
         <button className='bg-blue text-white text-[14px]  p-[5px_9px] rounded-[8px]' onClick={() => downloadPdf()}>Download ticket</button>
        <div className='max-w-[900px] max-[900px]:ml-[20px] max-[900px]:mr-[20px] bg-white shadow rounded-[10px] overflow-auto p-[10px] max-h-[60vh]'>
            <div  ref={targetRef} className=' pb-[20px] pl-[40px] pr-[40px] pt-[50px] pdf-container max-w-[900px] bg-white   h-[690px]'>
                <h1 className='text-center text-[30px] font-[700] max-[500px]:text-[20px]'>Exam Confirmation Ticket</h1>
                <p className='text-[20px] max-[500px]:text-[18px]  mt-[40px]'>Candidate's Name: {randevuPdfModal?.name}</p>
                <p className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'>Teaching Center: {randevuPdfModal?.center}</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'>Exam Details:</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'> Date: {randevuPdfModal?.date}</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'>Time: {randevuPdfModal?.time}</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'>Location: CELT White City, Mərkəzi Şərq küç, Baku 1001, Whitecity, Baku</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[40px]'> Please Note:</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'> 1. Kindly arrive 10 minutes prior to the scheduled time.</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'> 2. For any queries or assistance, feel free to contact us at +994 50 415 04 02.</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'> 3. If you need to reschedule your exam, please get in touch with your CELT Center.</p>

                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[40px]'>Best Regards,</p>
                <p  className='text-[20px] max-[500px]:text-[18px]  mt-[10px]'>CELT Colleges Team</p>
            </div>
        </div>
    </div>
  )
}

export default RandevuPdf
